import React from "react";
import "../Intro/Intro.scss";
import gsap, { TimelineLite, TweenMax, Linear, CSSRulePlugin, ScrollTrigger } from "gsap/all";
import Abacus from "../../Svgs/Abacus";
import Archive from "../../Svgs/Archive";
import BadgeDollar from "../../Svgs/BadgeDollar";
import Bitcoin from "../../Svgs/Bitcoin";
import Brackets from "../../Svgs/Brackets";
import BracketsCurly from "../../Svgs/BracketsCurly";
import Brain from "../../Svgs/Brain";
import Browser from "../../Svgs/Browser";
import Btc from "../../Svgs/Btc";
import CartPlus from "../../Svgs/CartPlus";
import ChartArea from "../../Svgs/ChartArea";
import ChartBar from "../../Svgs/ChartBar";
import ChartLine from "../../Svgs/ChartLine";
import ChessQueenAlt from "../../Svgs/ChessQueenAlt";
import ClipboardList from "../../Svgs/ClipboardList";
import CloudDownload from "../../Svgs/CloudDownload";
import CloudUpload from "../../Svgs/CloudUpload";
import Code from "../../Svgs/Code";
import CodeBranch from "../../Svgs/CodeBranch";
import CodeMerge from "../../Svgs/CodeMerge";
import Coin from "../../Svgs/Coin";
import Compass from "../../Svgs/Compass";
import ComputerClassic from "../../Svgs/ComputerClassic";
import ComputerSpeaker from "../../Svgs/ComputerSpeaker";
import Crop from "../../Svgs/Crop";
import Database from "../../Svgs/Database";
import Debug from "../../Svgs/Debug";
import Desktop from "../../Svgs/Desktop";
import DollarSign from "../../Svgs/DollarSign";
import Drone from "../../Svgs/Drone";
import Ethereum from "../../Svgs/Ethereum";
import FilePdf from "../../Svgs/FilePdf";
import FileSearch from "../../Svgs/FileSearch";
import Fingerprint from "../../Svgs/Fingerprint";
import Flashlight from "../../Svgs/Flashlight";
import FolderOpen from "../../Svgs/FolderOpen";
import Function from "../../Svgs/Function";
import GiftCard from "../../Svgs/GiftCard";
import GreaterThan from "../../Svgs/GreaterThan";
import HandshakeAlt from "../../Svgs/HandshakeAlt";
import Hashtag from "../../Svgs/Hashtag";
import Hdd from "../../Svgs/Hdd";
import Heading from "../../Svgs/Heading";
import HeadVr from "../../Svgs/HeadVr";
import Headphones from "../../Svgs/Headphones";
import Hone from "../../Svgs/Hone";
import Htwo from "../../Svgs/Htwo";
import Hthree from "../../Svgs/Hthree";
import Hfour from "../../Svgs/Hfour";
import Icursor from "../../Svgs/Icursor";
import Images from "../../Svgs/Images";
import InfoCircle from "../../Svgs/InfoCircle";
import Italic from "../../Svgs/Italic";
import Keyboard from "../../Svgs/Keyboard";
import Laptop from "../../Svgs/Laptop";
import LaptopCode from "../../Svgs/LaptopCode";
import LockAlt from "../../Svgs/LockAlt";
import LockOpen from "../../Svgs/LockOpen";
import MailBulk from "../../Svgs/MailBulk";
import MapMarked from "../../Svgs/MapMarked";
import MapMarker from "../../Svgs/MapMarker";
import Memory from "../../Svgs/Memory";
import Microchip from "../../Svgs/Microchip";
import Mobile from "../../Svgs/Mobile";
import MobileAndroid from "../../Svgs/MobileAndroid";
import Mouse from "../../Svgs/Mouse";
import MouseAlt from "../../Svgs/MouseAlt";
import Narwhal from "../../Svgs/Narwhal";
import NotEqual from "../../Svgs/NotEqual";
import Otter from "../../Svgs/Otter";
import Palette from "../../Svgs/Palette";
import ParachuteBox from "../../Svgs/ParachuteBox";
import PhoneLaptop from "../../Svgs/PhoneLaptop";
import PiggyBank from "../../Svgs/PiggyBank";
import Podcast from "../../Svgs/Podcast";
import ProjectDiagram from "../../Svgs/ProjectDiagram";
import QrCode from "../../Svgs/QrCode";
import Redo from "../../Svgs/Redo";
import Retweet from "../../Svgs/Retweet";
import Save from "../../Svgs/Save";
import SdCard from "../../Svgs/SdCard";
import SearchDollar from "../../Svgs/SearchDollar";
import Server from "../../Svgs/Server";
import ShareAlt from "../../Svgs/ShareAlt";
import ShieldAlt from "../../Svgs/ShieldAlt";
import ShippingFast from "../../Svgs/ShippingFast";
import ShippingTimed from "../../Svgs/ShippingTimed";
import ShoppingCart from "../../Svgs/ShoppingCart";
import SignalStream from "../../Svgs/SignalStream";
import SimCard from "../../Svgs/SimCard";
import SpaceStation from "../../Svgs/SpaceStation";
import Store from "../../Svgs/Store";
import Stream from "../../Svgs/Stream";
import Tablet from "../../Svgs/Tablet";
import TabletAndroid from "../../Svgs/TabletAndroid";
import Tags from "../../Svgs/Tags";
import Terminal from "../../Svgs/Terminal";
import ThumbsUp from "../../Svgs/ThumbsUp";
import Thumbtack from "../../Svgs/Thumbtack";
import TvMusic from "../../Svgs/TvMusic";
import Upload from "../../Svgs/Upload";
import UsbDrive from "../../Svgs/UsbDrive";
import UserCircle from "../../Svgs/UserCircle";
import UserFriends from "../../Svgs/UserFriends";
import Users from "../../Svgs/Users";
import ValueAbsolute from "../../Svgs/ValueAbsolute";
import VectorSquare from "../../Svgs/VectorSquare";
import Video from "../../Svgs/Video";
import WatchFitness from "../../Svgs/WatchFitness";
import Webcam from "../../Svgs/Webcam";
import Wifi from "../../Svgs/Wifi";
import Window from "../../Svgs/Window";
gsap.registerPlugin(CSSRulePlugin, ScrollTrigger);

class Intro extends React.Component {
  state = {
    load: true,
    delay: false
  }

  constructor(props) {
    super(props);
    // elements to animate
    this.txtOne = null;
    this.textTwo = null;
    this.textThree = null;
    this.bdlLogo = null;
		// animation tween
    this.introAni = null;
    // icon cont
    this.iconOne = null;
    this.iconTwo = null;
    this.iconThree = null;
    this.iconFour = null;
    this.iconFive = null;

	}

  handleTop = () => {
    if(!this.state.load){
      return
    }
    let viewHeight = Math.floor(Math.random() * 91)
    return viewHeight
  }

  handleLeft = () => {
    if(!this.state.load){
      return
    }
    let viewWidth = Math.floor(Math.random() * 91) - 20
    return viewWidth
  }

  handleSpeed = () => {
    let speed = Math.floor(Math.random() * 4) + 13
    return speed
  }

  handleTransform = () => {
    return '100'
  }

  componentDidMount(){
    this.setState({
      load: false
    }, ()=>{
      setTimeout(()=>{
        this.setState({
          delay: true
        })
      }, 1000)
    })

    // Entrance timeline
    this.introAni = new TimelineLite()
    this.bdlLogo = document.getElementsByClassName("bdl-logo-img");
    this.txtOne = document.getElementsByClassName("txt-1");
    this.txtTwo = document.getElementsByClassName("txt-2");
    this.txtThree = document.getElementsByClassName("txt-3");

    this.iconOne = document.getElementsByClassName("icon-cont-1");
    this.iconTwo = document.getElementsByClassName("icon-cont-2");
    this.iconThree = document.getElementsByClassName("icon-cont-3");
    this.iconFour = document.getElementsByClassName("icon-cont-4");
    this.iconFive = document.getElementsByClassName("icon-cont-5");

    // // r = 100;
    // // adjustJank = 4;

    // var rightCont = document.getElementsByClassName('intro-right');
    // console.log(rightCont[0]);

    // // rightCont[0].children(()=> {
    // //   var d = this.clientWidth;
    // //   console.log(d);
    // // })

      this.introAni
      .from(this.bdlLogo, .8, {opacity: 0, y: 100})
      .from(this.txtOne, .5, {opacity: 0, y: 50})
      .from(this.txtTwo, .3, {opacity: 0, y: 50})
      .from(this.txtThree, .3, {opacity: 0, y: 50});
    
    // Randomly place icons in container one
    let iconOneArr = this.iconOne[0].getElementsByTagName('svg');
    for (let i = 0; i < iconOneArr.length; i++) {
      // console.log(iconOneArr[i]); 
      // console.log(this.iconOne[0].clientWidth)
      // let canvasWidth = this.iconOne[0].clientWidth;
      // console.log('canvas width = ' + canvasWidth);
      let canvasHeight = this.iconOne[0].clientHeight;
      let y = Math.floor(Math.random() * (canvasHeight + 1)) + 1;
      let eachIcon = iconOneArr[i];
      eachIcon.style.top = y;
      eachIcon.style.position = 'relative';
    };
    // End random placement animations icon cont 1


    // Start animate icon container 1
    let iconOneAni = this.iconOne[0].cloneNode(true);
    // console.log("223", this.iconOne[0])
    // console.log("224", iconOneAni)
    var d = this.iconOne[0].clientWidth;
    // console.log("226", d);
    this.iconOne[0].style.width = d
    this.iconOne[0].parentNode.append(iconOneAni);
    let r = 8;
    let adjustJank = 5;
    let t = d/r;

    TweenMax.to( 
      this.iconOne[0].parentNode, 
      t,  // our calculated time
      {
        x: "-"+(d+adjustJank), 
        ease: Linear.easeNone,
        repeat: -1,
        }
    );
    //End animation icon cont 1
      

    // Randomly place icons in container two
    let iconTwoArr = this.iconTwo[0].getElementsByTagName('svg');
    for (let i = 0; i < iconTwoArr.length; i++) {
      let canvasHeightTwo = this.iconTwo[0].clientHeight;
      let yTwo = Math.floor(Math.random() * (canvasHeightTwo + 1)) + 1;
      let eachIconTwo = iconTwoArr[i];
      eachIconTwo.style.top = yTwo;
      eachIconTwo.style.position = 'relative';
    };
    // End placement icon cont 2
     // Start animate icon container 2
     let iconTwoAni = this.iconTwo[0].cloneNode(true);
     var dTwo = this.iconTwo[0].clientWidth;
     this.iconTwo[0].style.width = d
     this.iconTwo[0].parentNode.prepend(iconTwoAni);
     let rTwo = 4;
     let tTwo = dTwo/rTwo;
 
     TweenMax.to( 
       this.iconTwo[0].parentNode, 
       tTwo,  // our calculated time
        {
         x: dTwo+adjustJank, 
         ease: Linear.easeNone,
         repeat: -1,
         }
     );
     //End animation icon cont 2

    // Randomly place icons in container three
    let iconThreeArr = this.iconThree[0].getElementsByTagName('svg');
    for (let i = 0; i < iconThreeArr.length; i++) {
      let canvasHeight = this.iconThree[0].clientHeight;
      let y = Math.floor(Math.random() * (canvasHeight + 1)) + 1;
      let eachIcon = iconThreeArr[i];
      eachIcon.style.top = y;
      eachIcon.style.position = 'relative';
    };
    //End random placement icon cont 3
    // Start animate icon container 3
    let iconThreeAni = this.iconThree[0].cloneNode(true);
    var dThree = this.iconThree[0].clientWidth;
    this.iconThree[0].style.width = d
    this.iconThree[0].parentNode.append(iconThreeAni);
    let rThree = 2;
    let tThree = dThree/rThree;

    TweenMax.to( 
      this.iconThree[0].parentNode, 
      tThree,  // our calculated time
      {
        x: "-"+(dThree+adjustJank), 
        ease: Linear.easeNone,
        repeat: -1,
        }
    );
    //End animation icon cont 3

    // Randomly place icons in container four
    let iconFourArr = this.iconFour[0].getElementsByTagName('svg');
    for (let i = 0; i < iconFourArr.length; i++) {
      let canvasHeight = this.iconFour[0].clientHeight;
      let y = Math.floor(Math.random() * (canvasHeight + 1)) + 1;
      let eachIcon = iconFourArr[i];
      eachIcon.style.top = y;
      eachIcon.style.position = 'relative';
    };
    // End random placed icons cont 4
     // Start animate icon container 4
     let iconFourAni = this.iconFour[0].cloneNode(true);
     var dFour = this.iconFour[0].clientWidth;
     this.iconFour[0].style.width = d
     this.iconFour[0].parentNode.prepend(iconFourAni);
     let rFour = 6;
     let tFour = dFour/rFour;
 
     TweenMax.to( 
       this.iconFour[0].parentNode, 
       tFour,  // our calculated time
        {
         x: dFour+adjustJank, 
         ease: Linear.easeNone,
         repeat: -1,
         }
     );
     //End animation icon cont 4

    // Randomly place icons in container 5
    let iconFiveArr = this.iconFive[0].getElementsByTagName('svg');
    for (let i = 0; i < iconFiveArr.length; i++) {
      let canvasHeight = this.iconFive[0].clientHeight;
      let y = Math.floor(Math.random() * (canvasHeight + 1)) + 1;
      let eachIcon = iconFiveArr[i];
      eachIcon.style.top = y;
      eachIcon.style.position = 'relative';
    };
    // End random place container 5

    // Start animate icon container 5
    let iconFiveAni = this.iconFive[0].cloneNode(true);
    var dFive = this.iconFive[0].clientWidth;
    this.iconFive[0].style.width = d
    this.iconFive[0].parentNode.append(iconFiveAni);
    let rFive = 3;
    let tFive = dFive/rFive;

    TweenMax.to( 
      this.iconFive[0].parentNode, 
      tFive,  // our calculated time
      {
        x: "-"+(dFive+adjustJank), 
        ease: Linear.easeNone,
        repeat: -1,
        }
    );
    //End animation icon cont 5

  }

  handlePosition = (e) => {
    // console.log(e)
  }

  render() {
    return (
      <div className={"intro horizontal-inner-in"} >
      <div className={"intro-cont"}>
       <div className={"intro-left"}>
        <div className={"bdl-logo-cont"}>
         <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} className={"bdl-logo-img"} />
        </div>
        <div className="hero-txt">
          <h1>
            <div className="txt-1">A Full-Service</div>
            <div className="txt-2">Digital Agency</div>
          </h1>
            <p className="txt-3">BDL is a digital talent collective located in Los Angeles, CA. Comprised of industry experts, we design and implement data-driven digital strategies that drive results.
            </p>
        </div>
       </div>
       <div className={"intro-right"}>
        <div className={"icon-wrapper"}>
          <div className={"icon-cont-1"}>
            <Abacus />
            <Bitcoin />
            <CartPlus />
            <Code />
            <Database />
            <Ethereum />
            <Function />
            <HeadVr />
            <Terminal />
            <Mobile />
            <Microchip />
            <LaptopCode />
            <PhoneLaptop />
            <Server />
            <Window />
            <Video />
            <LockAlt />
            <MapMarked />
            <ParachuteBox />
            <Upload />
          </div>
        </div>
        <div className={"icon-wrapper-2"}>
        <div className={"icon-cont-2"}>
         <Archive />
         <Browser />
         <CloudDownload />
         <CodeBranch />
         <Coin />
         <Fingerprint />
         <Hashtag />
         <Hdd />
         <Wifi />
         <MobileAndroid />
         <NotEqual />
         <Laptop />
         <QrCode />
         <ShoppingCart />
         <Store />
         <Wifi />
         <WatchFitness />
         <SearchDollar />
         <Palette />
        </div>
        </div>
        <div className={"icon-wrapper-3"}>
        <div className={"icon-cont-3"}>
         <BadgeDollar />
         <Brackets />
         <ChartLine />
         <CodeMerge />
         <ComputerClassic />
         <Desktop />
         <Drone />
         <GiftCard />
         <HandshakeAlt />
         <Hone />
         <Icursor />
         <Microchip />
         <Tablet />
         <MouseAlt />
         <Keyboard />
         <Podcast />
         <Redo />
         <Retweet />
         <SdCard />
         <ThumbsUp />
         <Tags />
         <LockOpen />
         <ShareAlt />
         <ShieldAlt />
         <SimCard />
        </div>
        </div>
        <div className={"icon-wrapper-4"}>
        <div className={"icon-cont-4"}>
         <Brain />
         <Btc />
         <ChartBar />
         <ClipboardList />
         <Compass />
         <Crop />
         <Hfour />
         <FilePdf />
         <FolderOpen />
         <GreaterThan />
         <SpaceStation />
         <Heading />
         <Htwo />
         <Images />
         <Otter />
         <Mouse />
         <ProjectDiagram />
         <Save />
         <Stream />
         <TabletAndroid />
         <ShippingFast />
         <SignalStream />
         <ShippingTimed />
         <UserFriends />
         <Users />
        </div>
        </div>
        <div className={"icon-wrapper-5"}>
        <div className={"icon-cont-5"}>
         <BracketsCurly />
         <ChartArea />
         <ChessQueenAlt />
         <CloudUpload />
         <ComputerSpeaker />
         <Debug />
         <DollarSign />
         <FileSearch />
         <Flashlight />
         <Headphones />
         <Hthree />
         <Narwhal />
         <InfoCircle />
         <Italic />
         <PiggyBank />
         <Webcam />
         <MailBulk />
         <Memory />
         <MapMarker />
         <Thumbtack />
         <TvMusic />
         <UsbDrive />
         <UserCircle />
         <ValueAbsolute />
         <VectorSquare />
        </div>
        </div>
       </div>
      </div>

      </div>
    );
  }
}

export default Intro;