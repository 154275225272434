import React from 'react';
import Burger from "../Burger"
import Footer from "../Footer"
import "../BlogOne/BlogOne.scss";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    // EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    FacebookMessengerShareButton,
    // LinkedinIcon,
    // RedditIcon,
    // TelegramIcon,
    // TwitterIcon,
    FacebookShareButton
  } from "react-share";




const BlogOne =  () => {

    let location = useLocation();
    let currentUrl = "http://bdl.media" + location.pathname;
    let quote = "There are several necessary components to a successful token sale or ICO, but if you cant succinctly answer these 4 questions, stop."
    let title = "4 Questions a White Paper Needs to Answer"
    let description = "There are several necessary components to a successful token sale or ICO, but if you cant succinctly answer these 4 questions, stop. I’ve read 100+ ICO and Token Sale white papers in the past month…"
    let image = "../../../images/blog-imgs/blogOne/btc-book.jpeg"
    let hashtag = "#whitepaper"


    return (
        <>
        <div>
            <Burger />
        </div>
        <div className={"blog-temp-container"}>
            <Helmet>
             <title>{title}</title>
             <meta charset="utf-8" />
             <meta http-equiv="X-UA-Compatible" content="IE=edge" />
             <meta name="csrf_token" content="" />
             <meta property="type" content="website" />
             <meta property="url" content={currentUrl} />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
             <meta name="msapplication-TileColor" content="#ffffff" />
             <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
             <meta name="theme-color" content="#ffffff" />
             <meta name="_token" content="" />
             <meta name="robots" content="noodp" />
             <meta property="title" content={title} />
             <meta property="quote" content={quote} />
             <meta name="description" content={description} />
             <meta property="image" content={image} />
             <meta property="og:locale" content="en_US" />
             <meta property="og:type" content="website" />
             <meta property="og:title" content={title} />
             <meta property="og:quote" content={quote} />
             <meta property="og:hashtag" content={hashtag} />
             <meta property="og:image" content={image} />
             <meta content="image/*" property="og:image:type" />
             <meta property="og:url" content={currentUrl} />
             <meta property="og:site_name" content="CampersTribe" />
             <meta property="og:description" content={description} /> 
            </Helmet>

           <div className={"blog-temp-container-inner"}>
           <div className={"blog-temp-title-header"}>
             <div className={"blog-temp-title-header-logo-cont"}>
                <Link to={"/"}>
                    <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
                </Link>
            </div>
            <div className={"blog-temp-head-title-cont"}>
             <h1>{title}</h1>
            </div>
                </div>
            <div className={"blog-temp-cont"}>
             <div className={"blog-temp-title-cont"}>
                {/* <div>
                 <h1>4 Questions a White Paper Needs to Answer</h1>
                </div> */}
                <div className={"blog-temp-auth-cont"}>
                    <div className={"auth-info-cont"}>
                      <div className={"auth-img"}>
                        <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} />
                      </div>
                      <div className={"blog-temp-article-info"}>
                        <div className={"auth-name"}>
                            <span>by: </span>
                            <h3>Reza Jaffery</h3>
                        </div>
                        <div className={"date-time"}>
                            <span>April 25, 2018 - 7 minute Read</span>
                        </div>
                      </div>

                    </div>
                    <div className={"blog-temp-share-cont"}>
                        <FacebookShareButton 
                            url={currentUrl}
                            quote={quote}
                            hashtag={hashtag}
                            className={"share-btns"}>
                            <FacebookIcon size={36} round={true} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton>

                        </FacebookMessengerShareButton>

                    </div>
                    
                </div>
             </div>
             <div className={"blog-temp-body"}>
                <div>
                <p><i>There are several necessary components to a successful token sale or ICO, but if you cant succinctly answer these 4 questions, stop.</i></p>
                </div>
                <div>
                 <p><i>Please.</i></p>
                </div>
                <div>
                 <p>I’ve read 100+ ICO and Token Sale white papers in the past month. When I started my journey; I downloaded as many WP’s as I could find, and went to war.</p>
                </div>
                <div>
                 <img src={"../../../images/blog-imgs/blogOne/ieo-whitepaper-img-1.jpeg"} alt={"ieo-whitepaper-img-1"}/>
                </div>
                <div>
                    <p>
                    I mentioned in, <Link to={"https://hackernoon.com/top-icos-rated-by-white-paper-complexity-a7d2a2fda93d"}>“Ranking ICO White Papers by Complexity”</Link>, that I’ve been going through a large volume of white papers with the goal of determining best practices. I want to figure out if there is any commonality between the most successful projects.
                    </p>
                </div>
                <div>
                    <p>
                        It’s crazy how different white papers are from each other. Some companies put together graphic focused papers, with fancy diagrams and charts doing the heavy lifting. Some focus on the technical details of their project, no fluff, straight to the development. Others focus on the business case, the true need for the token or coin in its industry or category.
                    </p>
                </div>
                <div>
                    <p>
                        Despite how different they are from one another (besides the blatant rip-offs, you’d be surprised at how many white papers have been “copied & pasted” from other projects), the more successful ICO’s do have some common traits. Not in style, design, structure, or technicality; but in the main points, they try to drive home.
                    </p>
                </div>
                <div>
                    <p>
                        Pick any white paper and start flipping through it, next to each section: write down one vague sentence describing what that section is trying to say about the project. Repeat this and you’ll find that while white papers may differ in shape, size, and format — for the most part, they all follow (or attempt to follow) the same basic narratives.
                    </p>
                </div>
                <div>
                 <img src={"../../../images/blog-imgs/blogOne/btc-book.jpeg"} alt={"ieo-whitepaper-img-3"}/>
                </div>
                <div>
                    <p>I find that it’s easier to point out what ICO’s do wrong than what they do right. For example, most projects fail to describe why the reader should care about their existence.</p>
                </div>
                <div>
                    <h2>How to set yourself apart from the majority of blockchain projects.</h2>
                </div>
                <div>
                 <img src={"../../../images/blog-imgs/blogOne/ieo-whitepaper-img-2.jpeg"} alt={"ieo-whitepaper-img-2"}/>
                </div>
                <div>
                 <h4><span>When you’re writing anything intended to be persuasive, it’s imperative to answer the following questions as soon as possible:</span></h4>
                </div>
                <div>
                    <ul>
                        <li>Who are you?</li>
                        <li>What are you creating?</li>
                        <li>Why should I care?</li>
                        <li>How are you going to deliver what you’ve promised?</li>
                    </ul>
                </div>
                <div>
                    <p>Most projects do pretty well at the first two points, they falter when explaining why the reader should care, and how they plan on delivering — arguably the two most important questions listed.</p>
                </div>
                <div>
                    <p>These questions are pivotal because they lend themselves to several different sections of a white paper. The structure of a white paper could have an entire article all to itself, it depends on your project, and what you need to discuss to best highlight its benefits.</p>
                </div>
                <div>
                    <p>Now, let’s break down these questions into sections of a generic white paper.</p>
                </div>
                <div>
                    <ul>
                        <li>Who are you? <span>— Introduction/Team</span></li>
                        <li>What are you creating? <span> —Solution/ Product/Tokenomics</span></li>
                        <li>Why should I care? <span>—Problem/ Industry Overview/Market Inefficiencies/Technical Overview</span></li>
                        <li>How are you going to deliver what you’ve promised? <span>— Roadmap/Tokenomics/Token Utility/MVP/Use Case</span></li>
                    </ul>
                </div>
                <div>
                    <p>It’s extremely important that you spend time thinking about these questions, having well thought out answers will accelerate the process. It’s painfully obvious how thought out a token is when you talk to founders about writing their white paper. In my white paper workshops, I go over a series of questions, and a mind-map with the founders to get the meat of what needs to go into the WP. How serious they are about the business can often be shown by how quickly they’re able to answer the questions.</p>
                </div>
                <div>
                    <h2>
                        WHO ARE YOU
                    </h2>
                </div>
                <div>
                    <p>Ironically, in this world of trust-less systems, it’s all about trust. While a decentralized app or platform may govern itself, the people raising money to launch it are governed by the same human characteristics we all are.</p>
                </div>
                <div>
                    <p><strong>Tip:</strong> The “Team” section of the white paper is usually near the end, however, I’d suggest displaying it prominently on your website. So that when someone downloads your white paper, they see the team first. This way, if they do their own research, they’ll be able to see how qualified your team is before reading up on the problem you’re aiming to tackle.</p>
                </div>
                <div>
                    <p>We’ve all heard the stories of ICO teams raising a king’s ransom in Ethereum only to disappear to some island for the rest of their days. Your “Team” section is where you alleviate that concern.</p>
                </div>
                <div>
                    <p>The “Introduction” should tie in little tidbits about your team, but really, it’s about the bigger picture. Not who you are as a person, who you are as a company, a project.</p>
                </div>
                <div>
                    <p><i>What is it that you seek to do?</i></p>
                </div>
                <div>
                    <p><i>Why do you want to do it?</i></p>
                </div>
                <div>
                    <p><i>How will the world or your industry be different once you’ve completed it?</i></p>
                </div>
                <div>
                    <p>Explain your greater vision in your “Introduction”, and in “Team”, show them you have what it takes to execute on that vision.</p>
                </div>
                <div>
                    <h2>WHAT ARE YOU CREATING</h2>
                </div>
                <div>
                    <p>What is it that you’re trying to bring into the world? The answer to this question is your elevator pitch.</p>
                </div>
                <div>
                    <p>Like, <i>“Imagine AirBnB for Sloths”</i>, or, <i>“It’s like Uber, but for freelance Clowns”.</i></p>
                </div>
                <div>
                    <p>How is your company creating a solution? What does this solution look and feel like? Have you thought out the Tokenomics? Will any coins be burned? Why not use BTC or ETH instead of creating your own coin?</p>
                </div>
                <div>
                    <p>Answer the hardest questions before anyone has a chance to ask them. The internet will slaughter you if you try to hide behind vague buzz words and pretty diagrams.</p>
                </div>
                <div>
                    <p>Focus on functionality in product-centric sections, not the benefits users may receive.</p>
                </div>
                <div>
                    <h2>WHY SHOULD I CARE</h2>
                </div>
                <div>
                    <p>Initially, I was thinking about calling this article, “Why Should I Care?”. Because this is what I see companies struggle to answer the most in their white papers.</p>
                </div>
                <div>
                    <p><i>Founders, lend me your ears.</i></p>
                </div>
                <div>
                    <p>Focus on benefits, not functionalities. When you answered the last question, you talked about all the amazing functionalities your ICO is going to deliver. Now, it’s time to explain why anyone should give a flying f**k. What do all those functionalities mean to me as a user? How is the proprietary “SH1TCO1N hashing algorithm” you bragged about creating in prior sections going to benefit me as an individual?</p>
                </div>
                <div>
                    <p>Understand that most people who read your white paper are going to be unfamiliar with whatever industry you’re in. Don’t expect everyone who reads to know why your new “Uber for Freelance Clowns” concept is going to revolutionize the industry and bring family-friendly entertainment to birthday parties around the world.</p>
                </div>
                <div>
                    <h2>HOW ARE YOU GOING TO DELIVER WHAT YOU PROMISED</h2>
                </div>
                <div>
                    <h3>Roadmap</h3>
                </div>
                <div>
                    <p>When I look at a company roadmap I’m looking for one thing: feasibility. Do I think that the team can execute on the milestones they’ve outlined in their roadmap? Does anyone on their team have any relevant past experience?</p>
                </div>
                <div>
                    <p>If they’re building a marketplace, has anyone on the development team built a marketplace before?</p>
                </div>
                <div>
                    <p>
                    If they’re a B2B project, do members of the team have relationships they can rely on to generate leads?
                    </p>
                </div>
                <div>
                    <h3>Token Utility</h3>
                </div>
                <div>
                    <p>Describing your token utility is one of, if not the most important aspect of a white paper. It’s where you have to prove that you deserve to exist as a token economy.</p>
                </div>
                <div>
                    <p>
                    Why does your project need a token to exist? And if your answer is, “it doesn’t”, go home.
                    </p>
                </div>
                <div>
                    <p>Having a strong understanding of their tokens utility makes me far more confident in founders. I’m not interested in projects that have just decided to throw a token onto their product or platform because of the media hype.</p>
                </div>
                <div>
                    <h4><span>I want to work on projects that can’t exist without their tokens.</span></h4>
                </div>
                <div>
                    <p>
                    Those are the projects I get excited about. I believe the killer ICO’s and Token Sales of 2018 will be the companies that have found a way to utilize blockchain technology to fix something that is impossible to fix with anything other than blockchain. <Link to={"https://hackernoon.com/how-robotcache-is-going-to-disrupt-the-27-billion-pc-gaming-industry-3ec1901ec7ba"}>RobotCache</Link>, for example, Brian Fargo realized that if he created a digital game marketplace on the blockchain, customers would be able to sell pre-owned digital games for the first time — something that wouldn’t be possible without the immutable and transparent nature of Distributed Ledger Technology.
                    </p>
                </div>
                <div>
                    <p>They have some of the largest game developers in the world agreeing to allow users to sell used games online, and the only reason they’re comfortable with it is because they know blockchain provides a clear statement of ownership, and that one item can verifiably not exist in more than one place at a time.</p>
                </div>
                <div>
                    <p>It takes a true understanding of blockchain to create solutions that genuinely need the technology to improve, unfortunately, these necessary solutions are the minority in ICO’s.</p>
                </div>
                <div>
                    <p>
                    Here’s to hoping we see that change in 2018, but I’m not getting my hopes high enough to get hurt from the fall.
                    </p>
                </div>
                <div>
                    <p><strong>Want to talk to Reza about Whitepapers?<br></br>Shoot him an email <Link>here</Link></strong>
                    </p>
                    
                </div>




                
                
                
                
             </div>

            </div>
          </div>
        </div>
        <div>
            <Footer />
        </div>
        </>
    );
}

export default BlogOne;