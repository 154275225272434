import React from 'react';
import Burger from "../Burger"
import Footer from "../Footer"
import "../BlogOne/BlogOne.scss";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    // EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    FacebookMessengerShareButton,
    // LinkedinIcon,
    // RedditIcon,
    // TelegramIcon,
    // TwitterIcon,
    FacebookShareButton
  } from "react-share";




const BlogFour =  () => {

    let location = useLocation();
    let currentUrl = "http://bdl.media" + location.pathname;
    let quote = "Cryptocurrency investing is an emotional roller coaster, even for experienced traders. Don’t let your emotions control your actions."
    let title = "2 Things to Do While the Cryptocurrency Market Falls"
    let description = "Cryptocurrency investing is an emotional roller coaster, even for experienced traders. Don’t let your emotions control your actions."
    let image = "../../../images/blog-imgs/blogFour/ramen-soup.jpeg"
    let hashtag = "#CryptoTrading"


    return (
        <>
        <div>
            <Burger />
        </div>
        <div className={"blog-temp-container"}>
            <Helmet>
             <title>{title}</title>
             <meta charset="utf-8" />
             <meta http-equiv="X-UA-Compatible" content="IE=edge" />
             <meta name="csrf_token" content="" />
             <meta property="type" content="website" />
             <meta property="url" content={currentUrl} />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
             <meta name="msapplication-TileColor" content="#ffffff" />
             <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
             <meta name="theme-color" content="#ffffff" />
             <meta name="_token" content="" />
             <meta name="robots" content="noodp" />
             <meta property="title" content={title} />
             <meta property="quote" content={quote} />
             <meta name="description" content={description} />
             <meta property="image" content={image} />
             <meta property="og:locale" content="en_US" />
             <meta property="og:type" content="website" />
             <meta property="og:title" content={title} />
             <meta property="og:quote" content={quote} />
             <meta property="og:hashtag" content={hashtag} />
             <meta property="og:image" content={image} />
             <meta content="image/*" property="og:image:type" />
             <meta property="og:url" content={currentUrl} />
             <meta property="og:site_name" content="CampersTribe" />
             <meta property="og:description" content={description} /> 
            </Helmet>

           <div className={"blog-temp-container-inner"}>
           <div className={"blog-temp-title-header"}>
            <div className={"blog-temp-title-header-logo-cont"}>
                <Link to={"/"}>
                    <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
                </Link>
            </div>
            <div className={"blog-temp-head-title-cont"}>
             <h1>{title}</h1>
            </div>
            </div>
            <div className={"blog-temp-cont"}>
             <div className={"blog-temp-title-cont"}>
                {/* <div>
                 <h1>4 Questions a White Paper Needs to Answer</h1>
                </div> */}
                <div className={"blog-temp-auth-cont"}>
                    <div className={"auth-info-cont"}>
                      <div className={"auth-img"}>
                        <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} />
                      </div>
                      <div className={"blog-temp-article-info"}>
                        <div className={"auth-name"}>
                            <span>by: </span>
                            <h3>Reza Jaffery</h3>
                        </div>
                        <div className={"date-time"}>
                            <span>Feb 26, 2018 - 6 minute Read</span>
                        </div>
                      </div>

                    </div>
                    <div className={"blog-temp-share-cont"}>
                        <FacebookShareButton 
                            url={currentUrl}
                            quote={quote}
                            hashtag={hashtag}
                            className={"share-btns"}>
                            <FacebookIcon size={36} round={true} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton>

                        </FacebookMessengerShareButton>

                    </div>
                    
                </div>
             </div>
             <div className={"blog-temp-body"}>
                <div>
                    <h3>Besides crying, screaming, and other self-destructive behavior.</h3>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogFour/ramen-soup.jpeg"} alt={"ramen-soup"} />
                </div>
                <div>
                    <ol>
                        <li>Switch to a Ramen noodle diet.</li>
                        <li>Rock yourself back and forth in the fetal position.</li>
                    </ol>
                </div>
                <div>
                    <p><strong>Just kidding</strong></p>
                </div>
                <div>
                    <p>Please don’t do either of the above. Although I’m always a proponent of frugality, and genuinely believe Cheese flavored Ramen is one of the most underrated delicacies this world has to offer.</p>
                </div>
                <div>
                    <p>Cryptocurrency investing is an emotional roller coaster, even for experienced traders. Don’t let your emotions control your actions. Stick to your strategy, remember why you bought the coins you did, and don’t panic sell.</p>
                </div>
                <div>
                    <h4><span>What to do when the market falls depends on the specific position you’re in.</span></h4>
                </div>
                <div>
                    <p>If you’re already invested and are seeing your positions bleeding value, you’re going to want to behave differently than if you’re sitting in cash waiting for an opportunity to invest (which is the best position to be in when the market falls).</p>
                </div>
                <div>
                    <p>If you’re lucky enough to have your investment capital in cash when the market dips…</p>
                </div>
                <div>
                    <h2>Buy the Dip</h2>
                </div>
                <div>
                    <p>There’s a term used in cryptocurrency called, “BTFD”. Which stands for, “Buy the F***ing Dip”.</p>
                </div>
                <div>
                    <p>
                    Buying the dip, which means purchasing something when the price goes down, <strong>has been a winning strategy for Bitcoin investors since 2009.</strong> This concept isn’t new, there are quotes as old as Finance telling you to, “buy when there’s blood on the streets”.
                    </p>
                </div>
                <div>
                    <p>Here are some examples of <strong>“BTFD”</strong> in its natural habitat, Twitter.</p>
                </div>
                <hr />
                <div>
                    <blockquote className={"twitter-tweet"}><p lang={"en"} dir={"ltr"}>Fractals can help identify similar historical patterns and the KEY support/resistance.<br /><br />By identifying those key levels you can determine the risk/reward of a position.</p>&mdash; Luke Martin (@VentureCoinist) <a href={"https://twitter.com/VentureCoinist/status/967243690392535040?ref_src=twsrc%5Etfw"}>February 24, 2018</a></blockquote>
                    {/* <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
                </div>
                <hr />
                <div>
                    <blockquote class="twitter-tweet"><p lang="en" dir="ltr">wake up<br />btfd<br />sleep<br /><br />jk<br /><br />about the sleep</p>&mdash; Carry Wait (@CarryWait) <a href="https://twitter.com/CarryWait/status/967363188420423681?ref_src=twsrc%5Etfw">February 24, 2018</a></blockquote> 
                    {/* <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
                </div>
                <hr />
                <div>
                    <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Protip: Light more than one candle so she thinks it’s romantic, and you can hide the fact that you btfd instead of paying the power bill. <a href="https://t.co/ijliku9UgQ">pic.twitter.com/ijliku9UgQ</a></p>&mdash; The Juice (@CryptOJSimpson) <a href="https://twitter.com/CryptOJSimpson/status/966553315336118272?ref_src=twsrc%5Etfw">February 22, 2018</a></blockquote> 
                    {/* <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
                </div>
                <hr />
                <div>
                    <h2>Approach cryptocurrency investing like you approach grocery shopping. You’re looking for the best deal.</h2>
                </div>
                <div>
                    <p>When you go grocery shopping, you know what you want to buy, and you have an idea of what its worth. So if you go to buy bananas, but they’re out of season and marked up almost 20%, you might not buy bananas that week.</p>
                </div>
                <div>
                    <p>It’s the same with cryptocurrency. If you believe something has value and will accrue more value in the future, buy it. If you think something is already overvalued, or even if the market has made the value of something increase beyond what you’re willing to spend on it — don’t.</p>
                </div>
                <div>
                    <p>Let’s say that a coin goes from $5 to $20 during an uptrend, only to come down to $10 when the market falls.</p>
                </div>
                <div>
                    <p>Is this a discount from $20 or is it marked up from $5?</p>
                </div>
                <div>
                    <p>It’s both. The coin can go either way, but at this point, given that it’s found its temporary bottom at $10 and hasn’t fallen further. I’d consider it more likely to resume up towards $20 and beyond. On top of that, my downside is $5, and my upside is $10.</p>
                </div>
                <div>
                    <p>This example is extremely over-simplified, but you get the gist. In reality there are many more variables that need to be considered when weighing risk vs reward.</p>
                </div>
                <div>
                    <p>Moral of the story, the more articles you see calling this, “The End of Bitcoin”, the more bullish you should be with your investments. Don’t pay attention to the news (CNN, CNBC, etc.). The last time CNBC did a segment on “How to Buy Ripple”, they almost called the exact top of the uptrend. Almost immediately after the segment aired Ripple ($XRP) plummeted over 50%.</p>
                </div>
                <div>
                    <h2>Study</h2>
                </div>
                <div>
                    <h4><span>“Formal education will make you a living; self-education will make you a fortune."<br />– Jim Rohn</span></h4>
                </div>
                <div>
                    <p>If you’re already invested and watching your bags lose value, take this time to learn from your mistakes.</p>
                </div>
                <div>
                    <p>Unfortunately, at the time I wasn’t sitting patiently in fiat waiting for the perfect opportunity to “buy the dip”. I had already invested everything I could afford to, at the top of a bull run that almost immediately stopped after I bought in.</p>
                </div>
                <div>
                    <p>My investment quickly lost 50% of it’s value — I couldn’t even open up my exchange without going into a fit of depression. I felt like an idiot.</p>
                </div>
                <div>
                    <p>Too stubborn to accept the loss, I started learning. Teaching myself about indicators, studying the market and its cycles, learning the different key players in the space — doing the due diligence I should have done before getting involved at all.</p>
                </div>
                <div>
                    <p>Check out <Link to={"https://stockcharts.com/"} target={"_blank"}>StockCharts</Link> and start studying different chart patterns. Technical Analysis is considered a “Pseudo Science” by many but I find that it applies more in cryptocurrency than traditional stock markets. Perhaps due to the emotional state of the market.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogFour/chart-reading.png"} alt={"cryto-trading-trends-chart"} />
                </div>
                <div>
                    <p>Where the stock market has investors and traders with decades of experience. The majority of cryptocurrency investors are beginners, or “retail investors”, who are more prone to act emotionally during times of extreme volatility.</p>
                </div>
                <div>
                    <p>Institutional investors are only just beginning to flow into the market. There is a lack of maturity in the tools and resources available in the space for “big money” to get involved. Which has allowed this highly speculative and sentiment driven market to grow, unhindered, to the level of volatility it has today.</p>
                </div>
                <div>
                    <p>Technical Analysis (TA) ties in psychology to try and understand the possiblities that may occur at any given point in a charts history. It takes the volatility of the market and attempts to make sense of it. It’s important to understand that TA isn’t about predicting the future.</p>
                </div>
                <div>
                    <p>It’s about understanding all of the possible outcomes and preparing yourself to be in the best situation if any of those outcomes occur.</p>
                </div>
                <div>
                    <p>It’s all about <strong>risk management</strong>. For example: don’t try and catch the bottom of a downtrend (experienced traders call that “catching a falling knife”) to maximize your upside potential. Instead, wait for confirmation that the downtrend is over. This means that you will miss out on the initial gain the price see’s, but you’re also entering at a much lower risk than if you jumped in before an uptrend was confirmed.</p>
                </div>
                <div>
                    <p>Before you buy anything, you should study the chart for at least a month. Get to know the channels it moves between, study its history and see how its reacted to negative and positive news in the past.</p>
                </div>
                <div>
                    <h2>When you identify a coin that you think is a good investment:</h2>
                </div>
                <div>
                    <ul>
                     <li>Study the Chart</li>
                     <li>Identify what you believe is a good entry point</li>
                     <li>Set a limit order to buy at that price</li>
                     <li>Walk away.</li>
                    </ul>
                </div>
                <div>
                    <p>Believe it or not, the last step is often the hardest. One of the biggest issues I see people in our Trading Group facing is sticking to their strategy. They spend time researching entry points, but when they set their limit orders — they sit and wait for them to hit.</p>
                </div>
                <div>
                    <p>This is a <strong>TERRIBLE IDEA.</strong></p>
                </div>
                <div>
                    <p>Turn on the TV, play some video games, do <strong>ANYTHING</strong> besides staring at the chart until your order goes through. Staring at charts after you’ve set your buys is a great way to allow yourself to second guess your own decisions and shoot yourself in the foot.</p>
                </div>
                <div>
                    <p>
                    Figuring out good entry points is much harder than I make it seem here, I suggest finding a mentor or checking out a Technical Analysis course on <Link to={"https://www.udemy.com/"} target={"_blank"}>Udemy</Link>.
                    </p>
                </div>
                <div>
                    <p><strong>Further Education:</strong> If you want to be able to read charts and make your own decisions on charts, start off with some basic TA. Learn about <Link to={"https://school.stockcharts.com/doku.php?id=chart_analysis:candlesticks_and_traditional_chart_analysis&st=candlesticks"} target={"_blank"}>Candlestick Patterns</Link>, <Link to={"https://school.stockcharts.com/doku.php?id=chart_analysis:chart_patterns&st=chart%20patterns"} target={"_blank"}>Chart Patterns</Link>, <Link to={"https://school.stockcharts.com/doku.php?id=chart_analysis:support_and_resistance&st=support%20and%20resistance"} target={"_blank"}>Support & Resistance</Link>, and <Link to={"https://school.stockcharts.com/doku.php?id=technical_indicators"} target={"_blank"}>ALL of the indicators</Link> on StockCharts.</p>
                </div>
                <div>
                    <h2>My personal favorite Overlays/Indicators:</h2>
                </div>
                <div>
                    <p><strong><Link to={"https://school.stockcharts.com/doku.php?id=technical_indicators:moving_average_convergence_divergence_macd"} target={"_blank"}>MACD</Link></strong></p>
                </div>
                <div>
                    <p><strong><Link to={"https://school.stockcharts.com/doku.php?id=technical_indicators:relative_strength_index_rsi"} target={"_blank"}>RSI</Link></strong></p>
                </div>
                <div>
                    <p><strong><Link to={"https://school.stockcharts.com/doku.php?id=technical_indicators:stochrsi"} target={"_blank"}>Stochastic RSI</Link></strong></p>
                </div>
                <div>
                    <p><strong><Link to={"https://school.stockcharts.com/doku.php?id=technical_indicators:bollinger_bands"} target={"_blank"}>Bollinger Bands</Link></strong></p>
                </div>
                <div>
                    <h2>Once you have a solid understanding of the basics:</h2>
                </div>
                <div>
                    <p>Take a DEEP dive into <Link to={"https://school.stockcharts.com/doku.php?id=chart_analysis:fibonacci_retracemen&st=fibonnaci%20retracement"} target={"_blank"}>Fibonacci Retracement</Link> & <Link to={"market_analysis:introduction_to_elliott_wave_theory"} target={"_blank"}>Elliot Wave Theory</Link>. These are my personal favorites and I swear by them.</p>
                </div>


                









                
                
                
             </div>

            </div>
          </div>
        </div>
        <div>
            <Footer />
        </div>
        </>
    );
}

export default BlogFour;