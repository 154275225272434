import React, {useEffect} from "react";
import "./BlogPage.scss";
import Burger from "../Burger";
// import Abacus from "../../Svgs/Abacus";
// import Bitcoin from "../../Svgs/Bitcoin";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { TimelineMax, Elastic, Linear } from "gsap/all";


const BlogPage = () => {


useEffect(() => {
  const btnTimeline9 = new TimelineMax();
  const btnTimeline10 = new TimelineMax();
  const btnTimeline11 = new TimelineMax();
  const btnTimeline12 = new TimelineMax();
  const btnTimeline13 = new TimelineMax();
  const btnTimeline14 = new TimelineMax();
  const btnTimeline15 = new TimelineMax();
  const lm9 = document.getElementById("aniBtn9");
  const lm10 = document.getElementById("aniBtn10");
  const lm11 = document.getElementById("aniBtn11");
  const lm12 = document.getElementById("aniBtn12");
  const lm13 = document.getElementById("aniBtn13");
  const lm14 = document.getElementById("aniBtn14");
  const lm15 = document.getElementById("aniBtn15");


 
 // Button One
 lm9.addEventListener("mouseenter", animateBtn9);
 lm9.addEventListener("mouseleave", animateBtn9);
  
  btnTimeline9.to(lm9.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
  btnTimeline9.to(lm9, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
  btnTimeline9.reversed(true);
 
  function animateBtn9() {
   btnTimeline9.reversed(!btnTimeline9.reversed());
  }
  // End Button One
 
 // Button Two
  lm10.addEventListener("mouseenter", animateBtn10);
  lm10.addEventListener("mouseleave", animateBtn10);
   
   btnTimeline10.to(lm10.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
   btnTimeline10.to(lm10, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
   btnTimeline10.reversed(true);
  
   function animateBtn10() {
    btnTimeline10.reversed(!btnTimeline10.reversed());
   }
  //End Button 2
 
  // Button Three
  lm11.addEventListener("mouseenter", animateBtn11);
  lm11.addEventListener("mouseleave", animateBtn11);
   
   btnTimeline11.to(lm11.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
   btnTimeline11.to(lm11, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
   btnTimeline11.reversed(true);
  
   function animateBtn11() {
    btnTimeline11.reversed(!btnTimeline11.reversed());
   }
  //End Button 3
 
   // Button Four
   lm12.addEventListener("mouseenter", animateBtn12);
   lm12.addEventListener("mouseleave", animateBtn12);
    
    btnTimeline12.to(lm12.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline12.to(lm12, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline12.reversed(true);
   
    function animateBtn12() {
     btnTimeline12.reversed(!btnTimeline12.reversed());
    }
   //End Button 4

   // Button 5
   lm13.addEventListener("mouseenter", animateBtn13);
   lm13.addEventListener("mouseleave", animateBtn13);
    
    btnTimeline13.to(lm13.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline13.to(lm13, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline13.reversed(true);
   
    function animateBtn13() {
     btnTimeline13.reversed(!btnTimeline13.reversed());
    }
   //End Button 4


   // Button Four
   lm14.addEventListener("mouseenter", animateBtn14);
   lm14.addEventListener("mouseleave", animateBtn14);
    
    btnTimeline14.to(lm14.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline14.to(lm14, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline14.reversed(true);
   
    function animateBtn14() {
     btnTimeline14.reversed(!btnTimeline14.reversed());
    }
   //End Button 4


   // Button Four
   lm15.addEventListener("mouseenter", animateBtn15);
   lm15.addEventListener("mouseleave", animateBtn15);
    
    btnTimeline15.to(lm15.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline15.to(lm15, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline15.reversed(true);
   
    function animateBtn15() {
     btnTimeline15.reversed(!btnTimeline15.reversed());
    }
   //End Button 4
 
});

    return (
      <div className={"blog-page"}>
       <div>
        <Burger />
       </div>
       <div className={"inner-serv"}>
         <div className={"serv-page-logo-cont"}>
           <Link to={"/"}>
            <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
           </Link>
         </div>
         <div className={"serv-page-title-cont"}>
          <h1>Blog</h1>
         </div>
       </div>


       <div className={"blog-page-card-cont"}>
         <div className={"bp-inner-wrapper"}>

         <div className={"blog-page-category-cont"}>
            <div className={"blog-page-category-title"}>
              <h3>Content Writing</h3>
              <hr />
            </div>
            <div className={"blog-page-cartegory-card-cont"}>


            <div className={"blog-page-card-inner"}>
            <div className={"blog-page-card-new"}>
            <div id={"blogTopThree"}>
             <div className={"blog-title-new"}>
               <h2>A Framework for Writing That Resonates</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>SEO</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Sales Funnels</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Copy Writing</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>Using the 5 W’s to master content, sales funnels, copywriting, advertising, and more.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/a-framework-for-writing-that-resonates"}>
                 <svg id={"aniBtn10"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>
          </div>

          <div className={"blog-page-card-inner"}>
          <div className={"blog-page-card-new"}>
            <div id={"blogTopTwo"}>
             <div className={"blog-title-new"}>
               <h2>How to Consistently Rank Articles on the First Page of Google Organically</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>SEO</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Content Tips</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Branding</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>I used to think of myself as a digital marketer. Little did I know, my marketing mind was holding back my content.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/how-to-consistently-rank-articles-on-the-first-page-of-google-organically"}>
                 <svg id={"aniBtn11"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>
          </div>

            </div>
         </div>

         <div className={"blog-page-category-cont"}>
            <div className={"blog-page-category-title"}>
              <h3>Cryptocurrency</h3>
              <hr />
            </div>
            <div className={"blog-page-cartegory-card-cont"}>

            <div className={"blog-page-card-inner"}>
            <div className={"blog-page-card-new"}>
            <div id={"blogTopFour"}>
             <div className={"blog-title-new"}>
               <h2>4 Questions a White Paper Needs to Answer</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>IEO White Papers</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>There are several necessary components to a successful token sale or ICO, but if you cant succinctly answer these 4 questions, stop.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/4-questions-a-white-paper-needs-to-answers"}>
                 <svg id={"aniBtn9"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>
          </div>


          <div className={"blog-page-card-inner"}>
          <div className={"blog-page-card-new"}>
            <div id={"blogTopOne"}>
             <div className={"blog-title-new"}>
               <h2>Top ICO’s Rated by White Paper Complexity</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
              <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>IEO Whitepapers</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>Studying the correlation between the readability of white papers and the money raised.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/top-icos-rated-by-white-paper-complexity"}>
                 <svg id={"aniBtn12"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>
          </div>


          <div className={"blog-page-card-inner"}>
          <div className={"blog-page-card-new"}>
            <div id={"blogTopFive"}>
             <div className={"blog-title-new"}>
               <h2>Intro to Tokenized Assets and Security Tokens</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Decentralized Currencies</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Trading Tips</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>What are Tokenized Assets what are the problems they solve, and real-world use cases of how they can benefit existing assets.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/intro-to-tokenized-assets-and-security-tokens"}>
                 <svg id={"aniBtn13"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
          </div>
          </div>


          <div className={"blog-page-card-inner"}>
          <div className={"blog-page-card-new"}>
            <div id={"blogTopSix"}>
             <div className={"blog-title-new"}>
               <h2>3 Things Not to Do When Bitcoin is Going Down</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Trading Tips</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Decentralized Currencies</span></h3>
              </div>
            </div>
            <div className={"blog-except-new"}>
              <p>Trading or investing in cryptocurrency is a psychological war against yourself. Stock traders often say that investing in traditional markets requires extreme mental discipline.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/3-things-not-to-do-when-bitcoin-is-going-down"}>
                 <svg id={"aniBtn14"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
          </div>
          </div>


          <div className={"blog-page-card-inner"}>
          <div className={"blog-page-card-new"}>
            <div id={"blogTopSeven"}>
             <div className={"blog-title-new"}>
               <h2>2 Things to Do While the Cryptocurrency Market Falls</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Trading Tips</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Decentralized Currencies</span></h3>
              </div>
            </div>
            <div className={"blog-except-new"}>
              <p>Cryptocurrency investing is an emotional roller coaster, even for experienced traders. Don’t let your emotions control your actions.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/2-things-to-do-while-the-cryptocurrency-market-falls"}>
                 <svg id={"aniBtn15"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
          </div>
          </div>

            </div>
         </div>



         </div>
       </div>
       <div>
        <Footer />
       </div>
      </div>
    );
  }

export default BlogPage;