import React from 'react';
import Burger from "../Burger"
import Footer from "../Footer"
import "../BlogOne/BlogOne.scss";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    // EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    FacebookMessengerShareButton,
    // LinkedinIcon,
    // RedditIcon,
    // TelegramIcon,
    // TwitterIcon,
    FacebookShareButton
  } from "react-share";




const BlogSeven =  () => {

    let location = useLocation();
    let currentUrl = "http://bdl.media" + location.pathname;
    let quote = "I used to think of myself as a digital marketer. Little did I know, my marketing mind was holding back my content."
    let title = "How to Consistently Rank Articles on the First Page of Google Organically"
    let description = "I used to think of myself as a digital marketer. Little did I know, my marketing mind was holding back my content. Digital marketing was a new field where anyone could teach themselves how to become"
    let image = "../../../images/blog-imgs/blogSeven/how-to-rank-on-google-organically"
    let hashtag = "#seo"


    return (
        <>
        <div>
            <Burger />
        </div>
        <div className={"blog-temp-container"}>
            <Helmet>
             <title>{title}</title>
             <meta charset="utf-8" />
             <meta http-equiv="X-UA-Compatible" content="IE=edge" />
             <meta name="csrf_token" content="" />
             <meta property="type" content="website" />
             <meta property="url" content={currentUrl} />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
             <meta name="msapplication-TileColor" content="#ffffff" />
             <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
             <meta name="theme-color" content="#ffffff" />
             <meta name="_token" content="" />
             <meta name="robots" content="noodp" />
             <meta property="title" content={title} />
             <meta property="quote" content={quote} />
             <meta name="description" content={description} />
             <meta property="image" content={image} />
             <meta property="og:locale" content="en_US" />
             <meta property="og:type" content="website" />
             <meta property="og:title" content={title} />
             <meta property="og:quote" content={quote} />
             <meta property="og:hashtag" content={hashtag} />
             <meta property="og:image" content={image} />
             <meta content="image/*" property="og:image:type" />
             <meta property="og:url" content={currentUrl} />
             <meta property="og:site_name" content="CampersTribe" />
             <meta property="og:description" content={description} /> 
            </Helmet>

           <div className={"blog-temp-container-inner"}>
           <div className={"blog-temp-title-header"}>
             <div className={"blog-temp-title-header-logo-cont"}>
                <Link to={"/"}>
                    <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
                </Link>
            </div>
            <div className={"blog-temp-head-title-cont"}>
             <h1>{title}</h1>
            </div>
                </div>
            <div className={"blog-temp-cont"}>
             <div className={"blog-temp-title-cont"}>
                {/* <div>
                 <h1>4 Questions a White Paper Needs to Answer</h1>
                </div> */}
                <div className={"blog-temp-auth-cont"}>
                    <div className={"auth-info-cont"}>
                      <div className={"auth-img"}>
                        <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} />
                      </div>
                      <div className={"blog-temp-article-info"}>
                        <div className={"auth-name"}>
                            <span>by: </span>
                            <h3>Reza Jaffery</h3>
                        </div>
                        <div className={"date-time"}>
                            <span>July 17, 2019 - 10 minute Read</span>
                        </div>
                      </div>

                    </div>
                    <div className={"blog-temp-share-cont"}>
                        <FacebookShareButton 
                            url={currentUrl}
                            quote={quote}
                            hashtag={hashtag}
                            className={"share-btns"}>
                            <FacebookIcon size={36} round={true} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton>

                        </FacebookMessengerShareButton>

                    </div>
                    
                </div>
             </div>
             <div className={"blog-temp-body"}>
                <div>
                    <h2>The 6 Elements of Great Content</h2>
                </div>
                <div>
                    <p>I used to think of myself as a digital marketer. Little did I know, my marketing mind was holding back my content.</p>
                </div>
                <div>
                    <p>Digital marketing was a new field where anyone could teach themselves how to become adept with a few eLearning courses and some practical execution.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogSeven/how-to-rank-on-google-organically.jpeg"}  alt={"how-to-rank-on-google-organically.jpeg"} />
                </div>
                <div>
                    <p>I’m still a marketer in a way. But when someone asks what I do, I’m a writer. I still practice digital marketing: I still run ad campaigns every once in a while, create marketing strategies, consult for blockchain companies, and wear a ridiculous amount of hats.</p>
                </div>
                <div>
                    <p>However, writing is my practice. I write every day, it’s my craft. There are plenty of marketers out there, but focusing on my craft as a writer differentiates me.</p>
                </div>
                <div>
                    <p>The reason I’m bringing this up is that I didn’t truly understand good content marketing until I became a writer. It’s easy for a marketer to read a few SEO best practice articles, throw a blog up on Wordpress, and think that they’re running a content marketing campaign.</p>
                </div>
                <div>
                    <p>It’s easy to think that, it’s easy to be wrong.</p>
                </div>
                <div>
                    <p>If you want your content marketing or blogging to perform well it either needs to appease the gods of Google (by bringing value) or has to have money put behind it.</p>
                </div>
                <div>
                    <p>When I say “have money put behind it”, I mean buying placements for the article on other websites, or pushing it to custom audiences with Facebook advertising.</p>
                </div>
                <div style={{background: "#2fcc19", padding: "10px"}}>
                    <p><i>By the end of this article:<br /><br />I hope business owners realize that<br />a) content marketing isn’t dead<br />b) it’s not about how keyword dense an article is, but how many people it can influence<br />c) customers are influenced by people more than businesses.<br /><br />I hope writers realize that there is a huge opportunity for quality writers to generate income through freelance content creation.</i></p>
                </div>
                <div>
                    <h2>Good content isn’t written by a marketer</h2>
                </div>
                <div>
                    <p>Yes, it is important to have keywords in your article and yes it is important to follow a writing structure that allows Google to index your pages more easily. That does not mean you can throw up sub-par writing and expect customers to come flocking.</p>
                </div>
                <div>
                    <p>You’re better off sending an article on <Link to={"https://yoast.com/ultimate-guide-content-seo/"} target={"_blank"}>SEO best practices</Link> to a good writer and asking them to handle your content marketing than asking a marketer to do the same.</p>
                </div>
                <div>
                    <p>Before I started writing consistently, I had a few digital marketing clients who I performed some basic marketing and SEO services for. I would study up on the best practices according to Google at that point in time. I’d meta-tag the elements on every webpage.</p>
                </div>
                <div>
                    <p>Hell, I put so many keywords in those articles they looked like those ugly word clouds you see overused in corporate presentations.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogSeven/social-media-grpahic.png"}  alt={"social-media-graphic"} />
                </div>
                <div>
                    <p>I never had any complaints with my services, and at the time, I thought I was doing a damn good job.</p>
                </div>
                <div>
                    <p>I didn’t realize how wrong I was until I started writing consistently. When I started to publish content on Medium for myself; not for a client, not to make money, but because I needed a creative outlet.</p>
                </div>
                <div>
                    <p>I started to rank <strong>really</strong> well on Google.</p>
                </div>
                <div>
                    <p>In fact, several of my articles are on the first page of Google when you search for their subject matter, and a couple of them are the very first result.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogSeven/google-rankings.png"}  alt={"google-rankings"} />
                </div>
                <div>
                    <p>I didn’t write that article to promote anything. I just fell down the cryptocurrency rabbit hole. I was enthralled with the market and wanted to learn as much as I could. I started writing because it’s how I retain information.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogSeven/google-search-img.png"}  alt={"google-rankings"} />
                </div>
                <div>
                    <p>I like to take complex topics and write about them in a way that anyone can understand. It helps me increase my own comprehension. Someone once told me that if you can’t explain a concept to a layman, you don’t truly understand it yourself.</p>
                </div>
                <div>
                    <p><i>Writers create to provide value for their readers.</i></p>
                </div>
                <div>
                    <h4><span>Marketers write to take value from their readers.</span></h4>
                </div>
                <div>
                    <p>They want to appease their clients and generate traffic. The problem with that is clients don’t always know what’s best for their business (that’s why they marketers) and generating traffic is an ancillary goal.</p>
                </div>
                <div>
                    <p>If you make valuable content it <i>will</i> drive traffic.</p>
                </div>
                <div style={{background: "#2fcc19", padding: "10px"}}>
                    <p><i>I write really straight forward titles. I didn’t write any of these titles thinking, “How do I rank really well on Google?”. I titled them based on what I wrote about. By the time I finish writing and start thinking about titles: I’m too brain dead to be creative. I actually called a marketer friend of mine about this article and asked him to help me out with the headline. His advice was, if you want to go for the “beginner” audience then stick with something vague, if you want more adept readers, make your title actionable. I had this titled as “Why Your Content Marketing Sucks” originally, he inspired the change. So, while marketers may not be the best at writing content, they are at titling it!</i></p>
                </div>
                <div>
                    <h2>Good content isn’t bought in bulk</h2>
                </div>
                <div>
                    <p>It’s okay, I’m not judging you. We’ve all done it before. But were you really expecting that content farm in India to generate quality articles?</p>
                </div>
                <div>
                    <p>In the past, the approach to SEO has been based around putting as much keyword heavy content on your website as possible. I’ve seen companies throw up terrible articles on their website in hopes that it will help them rank for a few of the keywords. Keywords that are so frequently mentioned it’s obviously forced, and when something is obviously forced- it’s off-putting to the reader.</p>
                </div>
                <div>
                    <p>When something is off-putting to the reader, they don’t share it. If no one shares it, no one reads it, no one backlinks to it, and you might as well not have published it at all.</p>
                </div>
                <div>
                    <p>I’ve had companies give me prewritten content they bought from third-world countries in bulk. Their train of thought is that if they give me all the source material, it’ll be easier for me to generate content.</p>
                </div>
                <div>
                    <p>False.</p>
                </div>
                <div>
                    <p>It takes me much more time to take bad writing and make it good then it does to write from scratch. Looking at a terribly written article, knowing that it’s my job to improve it, gives me immense amounts of anxiety. It’s the damn worst.</p>
                </div>
                <div>
                    <p>The moral of the story is: you can get cheap content written in bulk for your company but it’s not going to generate much (if any) traffic, will create more work for you in the long run, and end up being a waste of money.</p>
                </div>
                <div>
                    <h2>Good content marketing isn’t a glorified advertisement</h2>
                </div>
                <div>
                    <p>No one enjoys being advertised to. I’ve never witnessed a person who’s watching TV or a Youtube video get excited when their desired content is interrupted by an advertisement.</p>
                </div>
                <div>
                    <p>As someone who has created and served advertisements before, I feel especially averse to them. Don’t get me wrong, I appreciate good advertising. When I see an ad that makes me want to click “Learn More” or whatever the call to action is, I do.</p>
                </div>
                <div>
                    <h3>Good advertising is difficult.</h3>
                </div>
                <div>
                    <p>Which is why your content should not be an advertisement for your product. It’s off-putting when people receive advertisements even when they’re expecting to be advertised to, imagine how they feel when it’s unexpected.</p>
                </div>
                <div>
                    <p>Not only that, if someone feels deceived after reading your article, (because they were covertly advertised to) then they probably aren’t going to trust the information you’re providing.</p>
                </div>
                <div>
                    <h2>Good content doesn’t always follow best practices</h2>
                </div>
                <div>
                    <p>In the constant journey to discover best practices, humans tend to make everything similar. When you find the “best” way to do something, why do it any different?</p>
                </div>
                <div>
                    <p>Well, because if everyone does something the same way, the product of that method loses value. It’s supply and demand. Listicles might have been amazing when there were only a few thousand of them on the internet, but when there are millions they begin to lose value.</p>
                </div>
                <div>
                    <p>That’s why Google is constantly changing their algorithm. Good content isn’t writing that was forced to fit within an arbitrary set of best practices that an intern at HubSpot wrote.</p>
                </div>
                <div>
                    <p>Good content brings value to the reader within the subject it’s focused on, and is unique: either in delivery, how it’s framed, or conceptually.</p>
                </div>
                <div>
                    <p>That doesn’t mean you can’t find inspiration from other content, it just means you can’t steal it. If you’re still going to steal it, and I can’t stop you- at least stop calling it “stealing” and say what all successful marketers say, “repurposing”.</p>
                </div>
                <div>
                    <h2>Good content is easy to find</h2>
                </div>
                <div>
                    <p>If you’re starting a brand new blog for your company (or even as a writer), how are people going to find it?</p>
                </div>
                <div>
                    <p>When you’re starting off, you need website traffic — it’s the reason you started a blog in the first place. The only problem is unless you’re willing to wait a very long time for the SEO juice on your site to start building up, you need another way to get attention.</p>
                </div>
                <div>
                    <h3>You have two options:</h3>
                </div>
                <div>
                    <ol>
                        <li>Use media buying and paid advertisements to increase the reach of your articles.</li>
                        <li>Re-publish your articles in publications with high traffic and similar audience demographics.</li>
                    </ol>
                </div>
                <div>
                    <p>You’ll notice that all the articles I’ve shown screenshots of in this article were published in <Link to={"https://hackernoon.com/"} target={"_blank"}></Link>Hacker Noon. Hacker Noon has a highly targeted audience of tech-focused readers. They are also home to one of the larger bases of cryptocurrency focused readers.</p>
                </div>
                <div>
                    <p>By publishing my content to their audience, I can benefit from all the hard work they’ve put in to turn their site into an authority on the subjects I write about.</p>
                </div>
                <div>
                    <p>If I published my article in Better Homes and Gardens or even a large publication like Entrepreneur, it probably wouldn’t have ranked as well. Because Hacker Noon has spent years building up SEO around cryptocurrency related terms — Google knows that if content on Hacker Noon is talking about cryptocurrency, it’s probably half-way decent.</p>
                </div>
                <div>
                    <p>If you’re an asshole, you might be thinking, “Well then you didn’t really accomplish anything you only rank well because of Hacker Noon”.</p>
                </div>
                <div>
                    <p>To which my response is: then why aren’t the other 25,000 articles about Bitcoin or Tokenized Assets on Hacker Noon showing up on the front page of Google? Huh Karen?</p>
                </div>
                <div>
                    <h2>Good content isn’t published under your company name</h2>
                </div>
                <div>
                    <p>People care about your story, people care about people. Think about the influencer marketing industry and how it has grown in the past decade. That wouldn’t be possible if what I’m saying is false: people trust people far more than they trust businesses.</p>
                </div>
                <div>
                    <p>It’s why you go to Yelp or check out reviews on Google before you go to a new restaurant. As a society, we have grown distrusting of businesses. Why would we trust a company whose main goal is to extract value from us in exchange for a good or service?</p>
                </div>
                <div>
                    <p>As the information on how marketing works becomes available to anyone with an internet connection, more people understand how the gigantic corporate marketing engine works.</p>
                </div>
                <div>
                    <p>The value of marketing is interesting, it’s the opposite of how I view the <Link to={"https://medium.com/swlh/the-true-cost-of-centralization-7b6da663ceba"} target={"_blank"}></Link>value of Bitcoin.</p>
                </div>
                <div style={{background: "#2fcc19", padding: "10px"}}>
                    <p><i>Bitcoin gains value as more and more people understand its utility (how it works) and how it provides security.<br /><br />Marketing loses value as more and more people understand its utility (how it works) and how it provides revenue.</i></p>
                </div>
                <div>
                    <p>As the general public (anyone who isn’t a marketer or a salesman in this case) becomes more knowledgable about how they’re advertised too, they get increasingly averse to it.</p>
                </div>
                <div>
                    <h3>Why is publishing content under your companies name a bad idea?</h3>
                </div>
                <div>
                    <p>Because no one cares what a company thinks.</p>
                </div>
                <div>
                    <p>What do you care about more, what SpaceX thinks about their new rocket ship, or what Elon Musk thinks about it?</p>
                </div>
                <div>
                    <p>Which of the following titles would you click on first:</p>
                    <ol>
                        <li>SpaceX Explains Why the Falcon Heavy is Badass</li>
                        <li>Elon Musk Explains Why the Falcon Heavy is Badass</li>
                    </ol>
                </div>
                <div>
                    <p>When you connect a statement to a person, it becomes more believable. That person reputation is now on the line, they have attached themselves to it — that, in the eyes of the consumer, makes it real.</p>
                </div>
                <div>
                    <p>If you’re a business that’s working on a content marketing strategy, have your blogs and articles published under one of your executives’ names, or your marketing manager. This will build a following around that member of your team, creating an additional marketing channel to reach potential customers around.</p>
                </div>
                <div>
                    <h3>What is Great Content?</h3>
                </div>
                <div>
                    <p>Great content is writing that provides something the reader can’t find anywhere else. That could be clear and concise information about a complex topic, it could be an emotion, it could even be information that has genuinely never surfaced until mentioned in your article. It answers questions that people in your industry are asking, in language that is easy to understand.</p>
                </div>
                <div>
                    <p>Great content is influential by nature of its value. When something has value that a reader can’t find elsewhere, they share it. Either so the people in their network think they’re smart for consuming the content, or because they genuinely want others to find the same value they did.</p>
                </div>



                
                
                
                
             </div>

            </div>
          </div>
        </div>
        <div>
            <Footer />
        </div>
        </>
    );
}

export default BlogSeven;