import React, { useState, useEffect, useRef } from 'react';
import Intro from "../Intro";
import About from "../About";
import Services from "../Services";
import Portfolio from "../Portfolio";
import Blog from "../Blog";
import Contact from "../Contact";
import Burger from "../Burger";
// import BtmMenu from "../BtmMenu";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Home.scss";


gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const Home = ()=> {

  const [ fill, setFill ] = useState(0)

  const homeRef = useRef(null)
  const aboutRef = useRef(null)
  const servicesRef = useRef(null)
  const portfolioRef = useRef(null)
  const blogRef = useRef(null)
  const contactRef = useRef(null)

  

  useEffect(() => {
    const onlyVert = (event) => {
      if (event.deltaX !== 0) {
        window.scroll(0, (event.deltaX * 2) + window.scrollY);
      }
      return;
    }
  
    const resize = () => {
      if(homeRef.current.clientWidth >= 1025) {
        window.addEventListener('wheel', onlyVert);
        ScrollTrigger.getById("myTrigger").enable();
        let element = document.getElementById("horizontalWrap")
        let s3Skew = document.getElementsByClassName("s3-skew")[0]
        let s4Skew = document.getElementsByClassName("s4-skew")[0]
        let navBar = document.getElementsByClassName('btm-nav')[0]
  
        element.style.flexFlow = "row nowrap"
        element.style.width = "600%"
        s3Skew.style.display = "unset"
        s4Skew.style.display = "unset"
        navBar.style.display = "unset"
  
      }
      else {
        window.removeEventListener('wheel', onlyVert);
        ScrollTrigger.getById("myTrigger").disable();
        let element = document.getElementById("horizontalWrap")
        let s3Skew = document.getElementsByClassName("s3-skew")[0]
        let s4Skew = document.getElementsByClassName("s4-skew")[0]
        let navBar = document.getElementsByClassName('btm-nav')[0]
  
        element.style.flexFlow = "column wrap"
        element.style.width= "100%"
        element.style.overflowX = "hidden"
        s3Skew.style.display = "none"
        s4Skew.style.display = "none"
        navBar.style.display = "none"
      }
    }
    
    let sections = gsap.utils.toArray(".horizontal-inner");
    // let sectionsIn = gsap.utils.toArray(".horizontal-inner-in");
    let container = document.getElementById("horizontalWrap");
    

    // console.log("sections ", sections);
    // console.log("sectionsIn ", sectionsIn);
    let timeline = gsap.timeline();


    timeline.to(sections, {
      x: () => -(container.scrollWidth - document.documentElement.clientWidth) + "px",
        ease: "none",
        scrollTrigger: {
          id: "myTrigger",
          invalidateOnRefresh: true,
          trigger: ".horizontal-wrap",
          pin: true,
          scrub: 1,
          // snap: 1 / (sections.length - 1),
          onUpdate: self => setFill(self.progress * 100),
          end: () => "+=" + document.querySelector(".horizontal-wrap").offsetWidth
        }
    });

    const navLinks = gsap.utils.toArray(".btm-nav-inner div");
      navLinks.forEach((link, i) => {
        link.addEventListener("click", e => {
          e.preventDefault();
          let progress = 0
          switch (link.textContent.toLowerCase()) {
            case "home":
              progress = 0
              break;
            
            case "about":
              progress = aboutRef.current?.offsetLeft / (contactRef.current?.offsetLeft)
              break;

            case "services":
              progress = (servicesRef.current?.offsetLeft / (contactRef.current?.offsetLeft)) - 0.01
              break;
            
            case "portfolio":
              progress = portfolioRef.current?.offsetLeft / (contactRef.current?.offsetLeft)
              break;
            
            case "blog":
              progress = (blogRef.current?.offsetLeft / (contactRef.current?.offsetLeft)) + 0.035

              break;

            case "contact":
              progress = contactRef.current?.offsetLeft / (contactRef.current?.offsetLeft)

              break;

            default:
              break;
          }
          
          gsap.to(sections, {
            end: ()=> {
              ScrollTrigger.getById("myTrigger").scroll(Math.ceil(container.clientWidth * progress))
            }
          })
        });
      });

    window.addEventListener('wheel', onlyVert);
    window.addEventListener("resize", resize)
    
    resize()

    return (()=> {
      (async function cleanUp() {
        window.removeEventListener('wheel', onlyVert);
        window.removeEventListener("resize", resize)

        ScrollTrigger.getById("myTrigger").disable();
        window.scroll(0,0)
      })();
    });
  }, []);

  return (
    <>
    <div className={"homepage-burger"}>
        <Burger />
    </div>
    <div id="horizontalWrap" className={"horizontal-wrap"}>
      <div className="horizontal-inner" id="home" ref={homeRef}>
        <Intro />
      </div>
      <div className="horizontal-inner" id="about" ref={aboutRef}>
        <About />
      </div>
      <div className="horizontal-inner" id="services" ref={servicesRef}>
        <Services />
      </div>
      <div className="horizontal-inner" id="portfolio" ref={portfolioRef}>
        <Portfolio />
      </div>
      <div className="horizontal-inner" id="blog" ref={blogRef}>
        <Blog />
      </div>
      <div className="horizontal-inner" id="contact" ref={contactRef}>
        <Contact />
      </div>
      

      <div className={"btm-nav"} >
        <div className={"btm-nav-inner"}>
          <span style={{width: `${fill}%`, height: "2px", backgroundColor: "#ff3800", position: "absolute", transition: 'width 500ms linear'}}></span>
          <div
            // onClick={()=>handleClick("home")}
            style={{cursor: "pointer", fontSize: '12', fontWeight: '600', position: "absolute", top: "-30px", transition: 'color 500ms linear', color: "#FF3800"}}>
            <span style={{height: "6px", width: "6px", position: "absolute", bottom: "-15px", backgroundColor: "#FF3800"}}></span>
            Home
          </div>
          <div
            // onClick={(event)=>{handleClick("about")}} 
            style={{cursor: "pointer", fontSize: '12', fontWeight: '600', position: "absolute", top: "-30px", left: `${aboutRef.current?.offsetLeft / (contactRef.current?.offsetLeft) * 100}%`, transition: 'color 500ms linear', color: fill >= aboutRef.current?.offsetLeft / (contactRef.current?.offsetLeft) * 100 ? "#FF3800" : null}}>
            <span style={{height: "6px", width: "6px", position: "absolute", bottom: "-15px", left: '0', backgroundColor: fill >= (aboutRef.current?.offsetLeft / (contactRef.current?.offsetLeft) * 100) ? "#FF3800" : "#a3a3a3"}}></span>
            About
          </div>
          <div 
            // onClick={()=>{handleClick("services")}}
            style={{cursor: "pointer", fontSize: '12', fontWeight: '600', position: "absolute", top: "-30px", left: `${(servicesRef.current?.offsetLeft / (contactRef.current?.offsetLeft) - 0.01) * 100}%`, transition: 'color 500ms linear', color: fill >= ((servicesRef.current?.offsetLeft / (contactRef.current?.offsetLeft) - 0.01) * 100) ? "#FF3800" : null}}>
            <span style={{height: "6px", width: "6px", position: "absolute", bottom: "-15px", left: '0', backgroundColor: fill >= ((servicesRef.current?.offsetLeft / (contactRef.current?.offsetLeft) - 0.01) * 100) ? "#FF3800" : "#a3a3a3"}}></span>
            Services
          </div>
          <div
            // onClick={()=>{handleClick("portfolio")}} 
            style={{cursor: "pointer", fontSize: '12', fontWeight: '600', position: "absolute", top: "-30px", left: `${portfolioRef.current?.offsetLeft / (contactRef.current?.offsetLeft) * 100}%`, transition: 'color 500ms linear' , color: fill >= portfolioRef.current?.offsetLeft / (contactRef.current?.offsetLeft) * 100 ? "#FF3800" : null}}>
            <span style={{height: "6px", width: "6px", position: "absolute", bottom: "-15px", left: '0', backgroundColor: fill >= portfolioRef.current?.offsetLeft / (contactRef.current?.offsetLeft) * 100 ? "#FF3800" : "#a3a3a3"}}></span>
            Portfolio
          </div>
          <div 
            // onClick={()=>{handleClick("blog")}}
            style={{cursor: "pointer", ontSize: '12', fontWeight: '600', position: "absolute", top: "-30px", left: `${(blogRef.current?.offsetLeft / (contactRef.current?.offsetLeft) + 0.035) * 100}%`, transition: 'color 500ms linear' , color: fill >= (blogRef.current?.offsetLeft / (contactRef.current?.offsetLeft) + 0.035) * 100 ? "#FF3800" : null}}>
            <span style={{height: "6px", width: "6px", position: "absolute", bottom: "-15px", left: "0", backgroundColor: fill >= (blogRef.current?.offsetLeft / (contactRef.current?.offsetLeft) + 0.035) * 100 ? "#FF3800" : "#a3a3a3"}}></span>
            Blog
          </div>
          <div 
            // onClick={()=>{handleClick("contact")}}
            style={{cursor: "pointer", fontSize: '12', fontWeight: '600', position: "absolute", top: "-30px", right: 0, transition: 'color 500ms linear', color: fill === 100 ? "#FF3800" : null}}>
            <span style={{height: "6px", width: "6px", position: "absolute", bottom: "-15px", right: '0', backgroundColor: fill === 100 ? "#FF3800" : "#a3a3a3"}}></span>
            Contact
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;