import React from 'react';
import "../ContactForm/ContactForm.scss";
import { Link } from "react-router-dom";
import * as emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
// import Recaptcha from "react-recaptcha";
import { useForm } from "react-hook-form";

init("user_XU6qitEJVQPQitgAQfINJ");

const ContactForm = () => {

const { register, handleSubmit, reset, errors } = useForm();

const onSubmit = (data, e, event) => {
  console.log(data);
  e.preventDefault();
  emailjs.sendForm('service_0zfwi3c', 'template_6s7rmrv', e.target, 'user_XU6qitEJVQPQitgAQfINJ')
  .then((result) => {
      console.log(result.text);
      document.getElementById("cpSuccessMessage").innerHTML = "Thank you for your interest in BDL, we will be in touch soon!";
      reset();
  }, (error) => {
      console.log(error.text);
      document.getElementById("cpErrorMessage").innerHTML = "Sorry something went wrong, please check for mistakes and try again";
  });
}

    return (
      <div className={"cp-contact horizontal-inner-in"}>
        <div className={"cp-contact-section-head"}>
          <h3>Want to Get in Touch?</h3>
        </div>
        <div className={"cp-contact-inner"}>
        <div className={"cp-form-cont"}>

        <form className="cp-contact-form" onSubmit={handleSubmit(onSubmit)}>
           <input type="hidden" name="contact_number" />
           <div className={"cp-form-input-cont"}>
            <label>Name *</label>
            <input type="text" name="user_name" ref={register({ required: true })}/>
            <div className={"input-error-message"}>
              { errors.user_name && <p>This is required</p> }
            </div>
           </div>
           <div className={"cp-form-input-cont"}>
            <label>Email *</label>
            <input type="email" name="user_email" ref={register({ required: true })}/>
            <div className={"cp-input-error-message"}>
              { errors.user_email && <p>This is required</p> }
            </div>
           </div>
           <div className={"cp-form-input-cont"}>
            <label>Phone *</label>
            <input type="phone" name="user_phone" ref={register({ required: true })} />
            <div className={"input-error-message"}>
              { errors.user_phone && <p>This is required</p> }
            </div>
           </div>
           <div className={"cp-form-input-cont"}>
            <label>Tell us how we can help you?</label>
            <textarea name="message" ref={register()} />
           </div>
           <input type="submit" value="Send" />
           
           <div className={"cp-form-message-cont"}>
              <span id={"cpSuccessMessage"}></span>
              <span id={"cpErrorMessage"}></span>
           </div>

        </form>
        </div>
      </div>
      </div>
    );
  }

export default ContactForm;