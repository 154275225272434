import React from 'react';
import "../Burger/Burger.scss";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { stack as Menu } from 'react-burger-menu'


const Burger =  () => {

    return (
      
      <Menu right >
       <div className='bmenu-link-cont'>
        <div className={"cir-menu-item"}>
        <Link to={"/"}>
           <h4>Home</h4>
         </Link>
       </div>
       <div className={"cir-menu-item"}>
         <Link to={"/about"}>
           <h4>About</h4>
         </Link>
       </div>
       <div className={"cir-menu-item"}>
         <Link to={"/services"}>
           <h4>Services</h4>
         </Link>
       </div>
       <div className={"cir-menu-item"}>
         <Link to={"/portfolio"}>
           <h4>Portfolio</h4>
         </Link>
       </div>
       <div className={"cir-menu-item"}>
         <HashLink to={"/blog"}>
           <h4>Blog</h4>
         </HashLink>
       </div>
       <div className={"cir-menu-item"}>
         <HashLink to={"/#contact"}>
           <h4>Contact</h4>
         </HashLink>
       </div>
      </div>
        
       <div className={"abs-menu-logo"}>
       <Link to={"/"}>
         <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"}/>
       </Link>
       </div>
      </Menu>
    
    );
}

export default Burger;