import React from "react";
import { Link } from "react-router-dom";
import "../Services/Services.scss";
// import ChessQueenAlt from "../../Svgs/ChessQueenAlt";
// import LaptopCodeServ from "../../Svgs/LaptopCodeServ"
import PaletteServ from "../../Svgs/PaletteServ"
import SearchDollarServ from "../../Svgs/SearchDollarServ"
import HashtagServ from "../../Svgs/HashtagServ"
import EthereumServ from "../../Svgs/EthereumServ";
import MobileServ from "../../Svgs/MobileServ";
import { TimelineMax, Elastic, Linear } from "gsap/all";


class Services extends React.Component {

componentDidMount() {
 let btnTimeline = new TimelineMax();
 let btnTimeline2 = new TimelineMax();
 let btnTimeline3 = new TimelineMax();
 let btnTimeline4 = new TimelineMax();
 let lmOne = document.getElementById("aniBtn1");
 let lmTwo = document.getElementById("aniBtn2");
 let lmThree = document.getElementById("aniBtn3");
 let lmFour = document.getElementById("aniBtn4");

// Button One
lmOne.addEventListener("mouseenter", animateBtn);
lmOne.addEventListener("mouseleave", animateBtn);
 
 btnTimeline.to(lmOne.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
 btnTimeline.to(lmOne, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
 btnTimeline.reversed(true);

 function animateBtn() {
  btnTimeline.reversed(!btnTimeline.reversed());
 }
 // End Button One

// Button Two
 lmTwo.addEventListener("mouseenter", animateBtn2);
 lmTwo.addEventListener("mouseleave", animateBtn2);
  
  btnTimeline2.to(lmTwo.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
  btnTimeline2.to(lmTwo, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
  btnTimeline2.reversed(true);
 
  function animateBtn2() {
   btnTimeline2.reversed(!btnTimeline2.reversed());
  }
 //End Button 2

 // Button Three
 lmThree.addEventListener("mouseenter", animateBtn3);
 lmThree.addEventListener("mouseleave", animateBtn3);
  
  btnTimeline3.to(lmThree.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
  btnTimeline3.to(lmThree, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
  btnTimeline3.reversed(true);
 
  function animateBtn3() {
   btnTimeline3.reversed(!btnTimeline3.reversed());
  }
 //End Button 3

  // Button Four
  lmFour.addEventListener("mouseenter", animateBtn4);
  lmFour.addEventListener("mouseleave", animateBtn4);
   
   btnTimeline4.to(lmFour.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
   btnTimeline4.to(lmFour, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
   btnTimeline4.reversed(true);
  
   function animateBtn4() {
    btnTimeline4.reversed(!btnTimeline4.reversed());
   }
  //End Button 4


}

  render() {
    return (
    <div className={"services horizontal-inner-in"}>
      <div className={"s3-skew"}></div>
        <div className={"s3-cont"}>
          <div className={"s3-inner-cont"}>
            <div className={"s3-section-heading"}> 
              <h3>Services</h3>
            </div>
            <div className={"s3-card-cont"}>
            <div className="s3-card">
              <div className="s3-card-top">
              <div className={"s3-svg"}>
                  <EthereumServ />
                </div>
                <div className={"s3-card-head"}>
                  <h4>Blockchain Development & Services</h4>
                </div>
              </div>
              <div className="s3-card-mid">
                <p>At BDL we have been heavily entreched in the blockchain community since 2017. With years of experience in this emerging technology that is disrupting billion dollar industries BDL will bring your dApps to life. We handle everything from token/smart contract development, to building full NFT platforms with fully customizable minting, and staking functionality.</p>
              </div>
              <div className="s3-card-btm">
               <div className={"s3-learn-more"}>
                 <Link to={"/services"}>
                  <svg id={"aniBtn1"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                  <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                  <text id={'btnTxt1'} className={"serv-btn-txt"} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#fff"}>Learn More</text>
                  </svg>
                </Link>
               </div>
              </div>
              </div>
              <div className="s3-card">
              <div className="s3-card-top">
               <div className={"s3-svg"}>
                  <MobileServ />
                </div>
                <div className={"s3-card-head"}>
                  <h4>Mobile and Web App Development</h4>
                </div>
              </div>
              <div className="s3-card-mid">
                <p>We turn napkin ideas into highly-functional applications. Our development process is agile, collaborative, and transparent. We keep you in the loop every step of the way and bi-weekly sprints and releases let you see your project come to life planned step by planned step.</p>
              </div>
              <div className="s3-card-btm">
                <div className={"s3-learn-more"}>
                 <Link to={"/services"}>
                  <svg id={"aniBtn2"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                   <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                   <text id={'btnTxt2'} className={"serv-btn-txt"} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#fff"}>Learn More</text>
                  </svg>
                </Link>
                </div>
              </div>
              </div>
              <div className="s3-card">
              <div className="s3-card-top">
                <div className={"s3-svg"}>
                  <PaletteServ />
                </div>
                <div className={"s3-card-head"}>
                  <h4>Web & Mobile Product Design</h4>
                </div>
              </div>
              <div className="s3-card-mid">
                <p>Our collaborative design process combines business and user research with branding, user experience and interface design. The result is a high-fidelity mockup, prototype or pitch-deck for you to use for fundraising or to begin engineering your new product or update an existing one.</p>
              </div>
              <div className="s3-card-btm">
                <div className={"s3-learn-more"}>
                <Link to="/services">
                 <svg id={"aniBtn3"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3 serv-btn-txt'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#fff"}>Learn More</text>
                </svg>
                </Link>
                </div>
              </div>
              </div>
              <div className="s3-card">
              <div className="s3-card-top">
                <div className={"s3-svg"}>
                  <SearchDollarServ />
                </div>
                <div className={"s3-card-head"}>
                  <h4>Marketing</h4>
                </div>
              </div>
              <div className="s3-card-mid">
                <p>Marketing and user acquisition are key functions in any business. Our approach begins with market segment research and complete audits of your business, brand, and company’s digital ecosystem to ensure that we have a full understanding of your consumer touch points and digital presence.</p>
              </div>
              <div className="s3-card-btm">
                <div className={"s3-learn-more"}>
                <Link to={"/services"}>
                 <svg id={"aniBtn4"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text id={'btnTxt4'} className={"serv-btn-txt"} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#fff"}>Learn More</text>
                 </svg>
                </Link>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
  </div>
    );
  }
}

export default Services;