import React from 'react';
import Burger from "../Burger"
import Footer from "../Footer"
import "../BlogOne/BlogOne.scss";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
//  EmailIcon,
 FacebookIcon,
//  FacebookMessengerIcon,
 FacebookMessengerShareButton,
//  LinkedinIcon,
//  RedditIcon,
//  TelegramIcon,
//  TwitterIcon,
 FacebookShareButton
} from "react-share";




const BlogFive =  () => {

    let location = useLocation();
    let currentUrl = "http://bdl.media" + location.pathname;
    let quote = "What are Tokenized Assets what are the problems they solve, and real-world use cases of how they can benefit existing assets."
    let title = "Intro to Tokenized Assets and Security Tokens"
    let description = "What are Tokenized Assets what are the problems they solve, and real-world use cases of how they can benefit existing assets."
    let image = "../../../images/blog-imgs/blogOne/btc-book.jpeg"
    let hashtag = "#tokenizedAssets"


    return (
        <>
        <div>
            <Burger />
        </div>
        <div className={"blog-temp-container"}>
            <Helmet>
             <title>{title}</title>
             <meta charset="utf-8" />
             <meta http-equiv="X-UA-Compatible" content="IE=edge" />
             <meta name="csrf_token" content="" />
             <meta property="type" content="website" />
             <meta property="url" content={currentUrl} />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
             <meta name="msapplication-TileColor" content="#ffffff" />
             <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
             <meta name="theme-color" content="#ffffff" />
             <meta name="_token" content="" />
             <meta name="robots" content="noodp" />
             <meta property="title" content={title} />
             <meta property="quote" content={quote} />
             <meta name="description" content={description} />
             <meta property="image" content={image} />
             <meta property="og:locale" content="en_US" />
             <meta property="og:type" content="website" />
             <meta property="og:title" content={title} />
             <meta property="og:quote" content={quote} />
             <meta property="og:hashtag" content={hashtag} />
             <meta property="og:image" content={image} />
             <meta content="image/*" property="og:image:type" />
             <meta property="og:url" content={currentUrl} />
             <meta property="og:site_name" content="CampersTribe" />
             <meta property="og:description" content={description} /> 
            </Helmet>

           <div className={"blog-temp-container-inner"}>
           <div className={"blog-temp-title-header"}>
             <div className={"blog-temp-title-header-logo-cont"}>
                <Link to={"/"}>
                    <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
                </Link>
            </div>
            <div className={"blog-temp-head-title-cont"}>
             <h1>{title}</h1>
            </div>
            </div>
            <div className={"blog-temp-cont"}>
             <div className={"blog-temp-title-cont"}>
                {/* <div>
                 <h1>4 Questions a White Paper Needs to Answer</h1>
                </div> */}
                <div className={"blog-temp-auth-cont"}>
                    <div className={"auth-info-cont"}>
                      <div className={"auth-img"}>
                        <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} />
                      </div>
                      <div className={"blog-temp-article-info"}>
                        <div className={"auth-name"}>
                            <span>by: </span>
                            <h3>Reza Jaffery</h3>
                        </div>
                        <div className={"date-time"}>
                            <span>September 27, 2018 - 9 minute Read</span>
                        </div>
                      </div>

                    </div>
                    <div className={"blog-temp-share-cont"}>
                        <FacebookShareButton 
                            url={currentUrl}
                            quote={quote}
                            hashtag={hashtag}
                            className={"share-btns"}>
                            <FacebookIcon size={36} round={true} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton>

                        </FacebookMessengerShareButton>

                    </div>
                    
                </div>
             </div>
             <div className={"blog-temp-body"}>
                <div>
                    <h3>What are Tokenized Assets what are the problems they solve, and real-world use cases of how they can benefit existing assets.</h3>
                </div>
                <div>
                    <p>A recent article on <Link to={"https://www.forbes.com/sites/rachelwolfson/2018/09/16/institutional-investors-bet-on-crypto-market-with-tokenized-securities/?sh=60a5db197a48"} target={"_blank"}>Forbes</Link> boldly stated, 2017 was the year of the utility token, 2018 was the year of realizing the mistake of the utility token, and 2019 will be the year of tokenized securities.</p>
                </div>
                <div>
                    <p>It’s clear at this point that ICO’s are not the future of blockchain. Not to say there won’t be any successful ICO’s in the future, but they aren’t the killer use cases blockchain needs to legitimize itself in the eyes of those who doubt it. It would seem that all eyes are on security tokens for the time being, but what makes security tokens such a big deal?</p>
                </div>
                <div>
                    <p>Many in the space think of security tokens with utility tokens as a frame of reference. When examing them and their relationship to utility tokens, it might just seem like throwing “security” before the word “token” means that the token is going to undergo more scrutiny and fall under traditional financial regulations. However, this understanding skirts over the main benefits brought from security tokens in the first place.</p>
                </div>
                <div>
                    <p>Before we dive into that, let’s go over some basic definitions so we’re all on the same page.</p>
                </div>
                <div>
                    <h2>Terminology</h2>
                </div>
                <div>
                    <p><i>All</i> <strong>tokenized assets</strong> <i>are classified as</i> <strong>security tokens.</strong></p>
                </div>
                <div>
                    <p><strong>Security Tokens</strong> <i>don't have to be</i> <strong>tokenized assets</strong></p>
                </div>
                <div>
                    <p><i>If a</i> <strong>security token</strong> <i>is tied to an</i> <strong>asset</strong> <i>then it is an</i>  <strong>asset-backed token</strong></p>
                </div>
                <div>
                    <p><strong>Tokens</strong> <i>generated to represent</i> <strong>assets</strong> <i>are</i> <strong>security tokens</strong></p>
                </div>
                <div>
                    <p><i>A</i> <strong>Security Token Offering (STO)</strong> <i>is the token sale event or "ICO" for a</i> <strong>Security Token</strong></p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogFive/hand-holding-coin.jpeg"} alt={"coin-holder"} />
                </div>
                <div>
                    <h2>Why Tokenized Assets are Important</h2>
                </div>
                <div>
                    <p>If you’re having trouble grasping why security tokens and tokenized assets are a big deal, think of it like this.</p>
                </div>
                <div>
                    <h4><span>The excitement around security tokens isn’t based on the idea that some existing tokens will now be considered securities. It’s based around the idea that we can now take existing securities and tokenize them.</span></h4>
                </div>
                <div>
                    <p>Blockchains killer use case might not end up being a dApp at all. Instead, it may be that the killer application we’ve been waiting to bring blockchain mainstream is something made possible through the inherent nature of the technology itself.</p>
                </div>
                <div>
                    <h4><span>Providing proof of ownership, and a transfer of value.</span></h4>
                </div>
                <div>
                    <p><i>Note: the words Security Token, Tokenized Asset, and Tokenized Security are often used interchangeably for each other.</i></p>
                </div>
                <div>
                    <p>I recently <Link to={"https://hackernoon.com/what-is-bakkt-59081e7df314"} target={"_blank"}>wrote that I wasn’t sure how to feel about the direction the market was going</Link>. Not relating to price action, but the types of blockchain projects that are beginning to gain traction above others. Since then, I’ve come to realize that this new wave of interest around security tokens is being generated by the same constructs that attract retail investors to cryptocurrencies. When it comes down to it, both retail investors and accredited/institutional investors are attracted to blockchain for its ability to lower barriers to entry and speed up existing processes.</p>
                </div>
                <div>
                    <p>With cryptocurrencies like Bitcoin, Litecoin, and Ethereum: virtually anyone with an internet connection can get ahold of some tokens. This drew in retail investors because it provided an opportunity the likes of which was previously unavailable to them. With Tokenized Assets and Security Tokens, they solve the end-users problems the same way cryptocurrencies solved retail investors problems, but they provide additional benefits that can be realized due to the archaic infrastructure around the securities market.</p>
                </div>
                <div>
                    <p>For example, the idea of “removing trust” from processes and transactions is a pillar of the blockchain community. Bitcoin gave people a way to transfer value without having to rely on a bank, security tokens remove trust in a similar fashion.</p>
                </div>
                <div>
                    <p>Think about how many companies you are trusting when you purchase a share of a company on the stock market. You’re entrusting the stock issuer, as well as long list of third-parties who help facilitate the process.</p>
                </div>
                <div>
                    <p><i>You’re trusting:</i></p>
                </div>
                <div>
                    <ul>
                        <li>Brokers</li>
                        <li>Transfer Agents</li>
                        <li>Registrars</li>
                        <li>Clelaring Firms</li>
                        <li>Custodians</li>
                        <li>and more</li>
                    </ul>
                </div>
                <div>
                  <p>If that same share was tokenized, the only entity you’d have to trust is the issuer.</p>  
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogFive/piggybank.jpeg"} alt={"piggy-bank"} />
                </div>
                <div>
                    <h2>What are Tokenized Assets?</h2>
                </div>
                <div>
                    <p>To tokenize something is to make the storage and management of an asset digitally represented by tokens. When someone is tokenizing an asset or security, they aren’t doing anything to the asset itself, they’re just changing the way that ownership of that asset is managed.</p>
                </div>
                <div>
                    <p>A <strong>security token</strong> is a digital token that represents a traditional private security. It can represent shares in a company, interest in a fund or trust, a home, an art collection, a farm, or essentially any asset that a person can own. To tokenize an asset and create a security token to represent it is to take something that is on paper, and start tracking ownership of it digitally.</p>
                </div>
                <div>
                    <p><i>Think of</i> <strong>assets</strong> <i>as</i> <strong>snail mail</strong></p>
                </div>
                <div>
                    <p><i>Think of</i> <strong>tokenized assets</strong> <i>as email</i></p>
                </div>
                <div>
                   <p>To send snail mail, you hand write (or type and then print) your message, take it to the post office, put it in an envelope, put in the receivers personal information, send it, and wait a few days for it to arrive. With email, well, you type out your message, a recipient email address, and press send.</p> 
                </div>
                <div>
                    <p>Making the process of sending mail digital made it faster, cheaper, and far more convenient. Beyond improving the process, tokenization</p>
                </div>
                <div>
                    <p>Anthony Pompliano captures the nature of Tokenized Assets in this quote:</p>
                </div>
                <div>
                    <h3>"If cryptocurrencies like Bitcoin are considered “programmable money” then you can consider Security Tokens a version of “programmable ownership.<br />” — Anthony Pompliano"</h3>
                </div>
                <div>
                    <p><i>Basically;</i></p>
                </div>
                <div>
                    <p><strong>Security tokens</strong> are a digital representations of value that are subject to regulation under security laws.</p>
                </div>
                <div>
                    <h2>Benefits of Tokenizing Assets</h2>
                </div>
                <div>
                    <p>Blockchain is changing the way that we buy and sell securities. To understand why the market will move towards this technology, we need to understand the current limitations of securities, and the benefits that tokenizing an asset brings.</p>
                </div>
                <div>
                    <h3>Liquidity Premium</h3>
                </div>
                <div>
                    <p>While certain securities have liquidity, such U.S. treasury bonds, private securities such as assets or private business interests are highly illiquid. This warrants something called an “illiquidity discount”, which is the price a user has to pay for trading an illiquid asset, and is typically around 20–30%. The opposite of an illiquidity discount is a liquidity premium, the increase in value derived from the higher liquidity of an asset. Tokenizing an asset opens up the potential investor base to a much larger audience by lowering the barriers to entry and making the process of buying and selling securities more accessible. It’s never been easier for international investors to access investments beyond borders than it is with tokenized assets.</p>
                </div>
                <div>
                    <h3>Lower Costs</h3>
                </div>
                <div>
                    <p>By removing third-parties from the process, tokenizing assets and using security tokens to represent ownership of them can dramatically decrease issuance costs.</p>
                </div>
                <div>
                    <h3>Faster Settlements</h3>
                </div>
                <div>
                    <p>Faster trade settlements are in part a byproduct of higher liquidity. The increase in speed is also derived from blockchain technology. Cryptocurrencies can typically settle in minutes, these tokens are far more simple than asset-backed security tokens, but serve as a benchmark for what’s possible in trade execution times.</p>
                </div>
                <div>
                    <h3>Compliance</h3>
                </div>
                <div>
                    <p>One of the largest headaches when dealing with securities is ensuring compliance across all regions or jurisdictions that an asset is traded in. Most regions have their own regulatory body with its own set of rules and guidelines, through tokenization, compliance can be automated through smart contracts and coded into the asset itself.</p>
                </div>
                <div>
                    <h4><span>Token-based and security-based assets are the future of the space because they attract institutional capital. With Tokenized Asset Offerings (TAO) and Security Token Offerings (STOs), the investment process starts with an asset, equity or cash flow, which institutional managers can value, understand and pitch to their LPs and constituents. Tokens just happen to be a vehicle that provide liquidity, transparency and cost-effective distribution.<br /> — Matthew Markham, COO, Blockchain Warehouse</span></h4>
                </div>
                <div>
                    <p><strong>Divisible: </strong>an often overlooked benefit of tokenizing assets is the fact that it makes them highly divisible. It’s easy to buy half a token, I’ve never heard of someone buying half of a car or house.</p>
                </div>
                <div>
                    <p><strong>Governance of Asset:</strong> the token can be used to represent voting rights and streamline the governance of assets. For example, a company can tokenize their shares and issue shares to their existing board members. They can decide that holding a certain amount of tokens entitles a person to a vote, and distribute that amount of tokens to those already invested in the company, as well as allow other investors to take part in the decision making process by offering that amount of tokens in a Security Token Offering.</p>
                </div>
                <div>
                    <i>Now that we understand the benefits, let's look at some examples of how tokenizing an asset works.</i>
                </div>
                <div>
                    <h2>Tokenized Asset Use Cases</h2>
                </div>
                <div>
                    <p>The current excitement around tokenized assets seems to be focused mostly around real estate, both commercial and residential. I see a huge amount of value that can be brought through tokenizing real estate, but in my eyes, this is the lowest hanging fruit. The following examples are use cases that I personally would like to work on and/or see develop in the future.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogFive/marijuana-farm.jpeg"} alt={"marijuana-farm"} />
                </div>
                <div>
                    <h3>Use case #1: Tokenized Marijuana Farm</h3>
                </div>
                <div>
                    <p>
                    A tokenized marijuana farm would represent the intersection of two exploding markets: medical/recreational marijuana and blockchain. Both markets have seen great growth in the past few years, and ironically both have been called a “bubble”. A friend of mine that I met through crypto recently told me that while the market was down he had invested into a marijuana farm, and that the profits he was seeing were far greater than any return he had achieved trading. I see the potential of tokenizing marijuana fields or hydroponic facilities as capturing the best of both worlds. Here’s one way it could work.
                    </p>
                </div>
                <div>
                    <p>For the sake of simplicity, let’s say we issue 10,000 tokens representing ownership of a 10,000 square foot facility.</p>
                </div>
                <div>
                    <p><strong>Tokenized Asset:</strong> each token can be backed by 1 square foot of facility.</p>
                </div>
                <div>
                    <p><strong>Use of Proceeds:</strong> buying additional land or warehouse space to increase the size of the growing operation and increase revenues.</p>
                </div>
                <div>
                    <p><strong>Cashflow to Token Holders:</strong> token holders are entitled to cash flow coming from the original facility and the additional farms generated through the initial token investment.</p>
                </div>
                <div>
                    <i>Note: if you’re in the marijuana industry and want to chat about tokenization, shoot me an email. Always happy to lend a helping hand.</i>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogFive/gamer.jpeg"} alt={"gamers"} />
                </div>
                <div>
                    <h3>Use case #2: Tokenized eSports Organization</h3>
                </div>
                <div>
                    <p>My career has taken me deeply into both eSports and Blockchain as industries. I was drawn to both for the same reasons: they’re emerging industries reminiscent to the wild west. When my friend Erik, the FaZe clan manager, sold an Overwatch player to another org, he effectively set the industry standard for how much Overwatch players are worth simply by being the first to put a price on them. That’s exciting. Many eSports organizations (often referred to as “orgs”) have thriving online communities that would be happy to support their favorite team, and already do through Twitch donations, monthly subscriptions, and purchasing merchandise. These same organizations often don’t have access to private investors unless they’re part of the top 1% of teams in terms of social media reach or tournament winnings. Tokenizing shares of an eSports organization can provide liquidity to organization owners, and access for investors who want to take part in an exciting new market. eSports orgs often pay salaries to their players, and will even put them in team housing. This means that acquiring top-tier gaming talent requires capital in order to stay competitive in terms of salary and living arrangements.</p>
                </div>
                <div>
                    <p><strong>Tokenized Asset:</strong> tokens are backed by private shares of the eSports organization.</p>
                </div>
                <div>
                    <p><strong>Use of Proceeds: </strong>acquiring top-tier eSports talent, expanding to compete in additional games, and marketing the teams brand for increased brand equity, sponsorship dollars, and merchandise sales.</p>
                </div>
                <div>
                    <p><strong>Source of Cash Flow to Token Holders: </strong>tournament winnings from existing and new eSports talent, sponsorships, merchandise sales, brand deals.</p>
                </div>
                <div>
                    <p>These two use cases are ideas that have me excited for the future of blockchain. There are countless assets that can benefit from tokenization, but if I could only work on two projects for the foreseeable future, I hope that they’d be similar to the use cases above.</p>
                </div>
             </div>

            </div>
          </div>
        </div>
        <div>
            <Footer />
        </div>
        </>
    );
}

export default BlogFive;