import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Home";
import AboutPage from './components/AboutPage/AboutPage';
import ServicesPage from './components/ServicesPage/ServicesPage';
import BlogPage from "./components/BlogPage";
import PortfolioPage from "./components/PortfolioPage";
import BlogOne from "./components/BlogOne";
import BlogTwo from "./components/BlogTwo";
import BlogThree from "./components/BlogThree";
import BlogFour from "./components/BlogFour";
import BlogFive from "./components/BlogFive";
import BlogSix from "./components/BlogSix";
import BlogSeven from "./components/BlogSeven";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <div>
        <Router>
         <Route exact path="/" component={Home} />
         <Route exact path="/services" component={ServicesPage} />
         <Route exact path="/about" component={AboutPage} />
         <Route exact path="/blog" component={BlogPage} />
         <Route exact path="/portfolio" component={PortfolioPage} />
         <Route exact path="/4-questions-a-white-paper-needs-to-answer" component={BlogOne} />
         <Route exact path="/top-icos-rated-by-white-paper-complexity" component={BlogTwo} />
         <Route exact path="/3-things-not-to-do-when-bitcoin-is-going-down" component={BlogThree} />
         <Route exact path="/2-things-to-do-while-the-cryptocurrency-market-falls" component={BlogFour} />
         <Route exact path="/intro-to-tokenized-assets-and-security-tokens" component={BlogFive} />
         <Route exact path="/a-framework-for-writing-that-resonates" component={BlogSix} />
         <Route exact path="/how-to-consistently-rank-articles-on-the-first-page-of-google-organically" component={BlogSeven} />
         <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
         <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        </Router>
      </div>
  );
}

export default App;
