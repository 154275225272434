import React from "react";
import "../ContentPage/ContentPage.scss";
import "../AboutPage/AboutPage.scss";
import Burger from "../Burger";
import Footer from "../Footer";
import ContactForm from "../ContactForm";
import { Link } from "react-router-dom";


class AboutPage extends React.Component {
    render() {
     return (
       <div className={"about-page"}>
        <div>
        <Burger />
        </div>
       <div className={"inner-serv"}>
         <div className={"serv-page-logo-cont"}>
           <Link to={"/"}>
            <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
           </Link>
         </div>
         <div className={"serv-page-title-cont"}>
          <h1>About</h1>
         </div>
       </div>
       <div className="about-page-cont">
           <div className="about-page-inner">
               <div className="about-page-mission">
                    <div>
                        <h3>Our Mission</h3>
                    </div>
                    <div>
                        <p>BDL's mission is to empower business owners in emerging industries by using transformative digital solutions to elevate their current business or bring their ideas to life.</p>
                    </div>
               </div>
               <div className="about-page-sep">
                    <hr />
               </div>
               <div className="about-page-top">
                    <div className="about-page-top-cont about-page-top-left">
                        <div className="aptl">
                            <h5>Our Goal is Long-Term Partnerships with Clients</h5>
                        </div>
                        <div>
                            <p>
                            Each of our projects helps businesses achieve outstanding results.
                            <br /><br />
                            You expect your partners to do more than complete a project following your specification. When you come to us with ideas, we help research them and offer solutions.
                            <br /><br />
                            Clients ask us how non-technical business leaders can evaluate and choose software developers. We believe that the criteria are the transparent business processes of the outsourcing company. A client controls the project progress by communicating with the manager and taking part in demonstrations of each stage outcomes. We explain how our processes lead to sustainable and predictable results before the start of the project.
                            </p>
                        </div>
                    </div>
                    <div className="about-page-top-cont about-page-top-right">
                            <div className="ap-stats-cont"> 
                                <div>
                                    <h5>2018</h5>
                                </div>
                                <div>
                                    <h6>Founded</h6>
                                </div>
                                
                            </div>
                            <div className="ap-stats-cont"> 
                                <div>
                                    <h5>25+</h5>
                                </div>
                                <div>
                                    <h6>Devlopers<br />from middle to senior</h6>
                                </div>
                            </div>
                            <div className="ap-stats-cont"> 
                                <div>
                                    <h5>60+</h5>
                                </div>
                                <div>
                                    <h6>Projects</h6>
                                </div>
                            </div>
                            <div className="ap-stats-cont"> 
                                <div>
                                    <h5>7</h5>
                                </div>
                                <div>
                                    <h6>Company-owned<br />products</h6>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="about-page-sep">
                    <hr />
               </div>
                <div className="about-page-mission">
                    <div>
                        <h3>Our Process</h3>
                    </div>
                    <div>
                        <p>To ensure a smooth and efficient partnership, our team utilizes a collaborative, agile approach centered around bi-weekly sprints. In doing this, we are able to establish achievable, well-defined goals and constantly communicate with your team to progress your project as quickly as possible. By utilizing lean startup principles and maintaining a focus on the user experience, we will deliver a Minimum Viable Product to use as a foundation for your project as we continue to iterate and improve it to achieve the perfect final product.</p>
                    </div>
               </div>
                <div className="about-page-sep">
                    <hr />
               </div>
               <div className="about-page-phil">
                   <div>
                       <h3>Our Core Values</h3>
                   </div>
                    <div className="ap-phil-row">
                        <div className="ap-phil-num">
                            <h6>01 <span>/</span></h6>
                        </div>
                        <div className="ap-phil-list">
                            <h4>Transparency Matters</h4>
                            <p>Integrity is at the core of our business. A successful relationship requires trust and honesty from both parties, and we will be 100% transparent from the beginning.</p>
                        </div>
                    </div>
                    <div className="ap-phil-row">
                        <div className="ap-phil-num">
                            <h6>02 <span>/</span></h6>
                        </div>
                        <div className="ap-phil-list">
                            <h4>Be Pioneers</h4>
                            <p>We push the boundaries in emerging markets and constantly bring new insight and innovation to challenge current market landscapes and give our customers an advantage by staying ahead of their competition.</p>
                        </div>
                    </div>
                    <div className="ap-phil-row">
                        <div className="ap-phil-num">
                            <h6>03 <span>/</span></h6>
                        </div>
                        <div className="ap-phil-list">
                            <h4>Innovate to Expand</h4>
                            <p>Innovation generates opportunities. We encourage exploring curiosity to create unconventional solutions and push businesses beyond their limits. </p>
                        </div>
                    </div>
                    <div className="ap-phil-row">
                        <div className="ap-phil-num">
                            <h6>04 <span>/</span></h6>
                        </div>
                        <div className="ap-phil-list">
                            <h4>Have a Clear Vision</h4>
                            <p>What is your value proposition? What problem does your product or service aim to solve? Conveying a uniform vision from the beginning helps us stay on course and produce an exceptional product.</p>
                        </div>
                    </div>
                    <div className="ap-phil-row">
                        <div className="ap-phil-num">
                            <h6>05 <span>/</span></h6>
                        </div>
                        <div className="ap-phil-list">
                            <h4>Be Intentional In Execution</h4>
                            <p>By maintaining a clear focus and working with urgency and passion, we promise to see your vision through and create a product that you can be proud of. </p>
                        </div>
                    </div>
               </div>
               <div className="about-page-sep">
                    <hr />
               </div>
               <div className="about-page-team">
                    <div>
                        <h3>Our Leaders</h3>
                    </div>
                    <div className="ap-team-cont">
                        
                        <div className="ap-team-card-cont">
                        <div className="ap-team-card">
                            <div className="ap-team-img" id="dan">
                            </div>
                            <div className="ap-team-job">
                                <div>
                                    <h4>Dan Lawrence</h4>
                                </div>
                                <div>
                                    <h5>Director of Media</h5>
                                </div>
                                <div className="ap-team-social-links">
                                    <Link to={'https://www.linkedin.com/in/daniel-lawrence-59115321a/'} target={'_blank'}>
                                        <img src={'../../images/social-icons/linkedin-logo.png'} alt={'linkedin'} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        <div className="ap-team-card-cont">
                            <div className="ap-team-card">
                                <div className="ap-team-img" id="mike">
                                </div>
                                <div className="ap-team-job">
                                    <div>
                                        <h4>Michael Heiser</h4>
                                    </div>
                                    <div>
                                        <h5>Director of DevOps / Development Project Lead</h5>
                                    </div>
                                    <div className="ap-team-social-links">
                                        <Link to={'https://www.linkedin.com/in/michael-heiser-media-buyer-web-dev/'} target={'_blank'}>
                                            <img src={'../../images/social-icons/linkedin-logo.png'} alt={'linkedin'} />
                                        </Link>
                                        <Link to={'https://twitter.com/Megga_eth'}target={'_blank'}>
                                            <img src={'../../images/social-icons/twitter-sign.png'} alt={'twitter'} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ap-team-card-cont">
                            <div className="ap-team-card">
                                <div className="ap-team-img" id="lou">
                                </div>
                                <div className="ap-team-job">
                                    <div>
                                        <h4>Lou Cesario</h4>
                                    </div>
                                    <div>
                                        <h5>Director of Business Development</h5>
                                    </div>
                                    <div className="ap-team-social-links">
                                        <Link to={'https://www.linkedin.com/in/louiecesario/'} target={'_blank'}>
                                            <img src={'../../images/social-icons/linkedin-logo.png'} alt={'linkedin'} />
                                        </Link>
                                        <Link to={'https://twitter.com/notoriousFARMER'} target={'_blank'}>
                                            <img src={'../../images/social-icons/twitter-sign.png'} alt={'twitter'} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ap-team-card-cont">
                            <div className="ap-team-card">
                                <div className="ap-team-img" id="dj">
                                </div>
                                <div className="ap-team-job">
                                    <div>
                                        <h4>DJ Schoonover</h4>
                                    </div>
                                    <div>
                                        <h5>Director of Operations</h5>
                                    </div>
                                    <div className="ap-team-social-links">
                                        <Link to={'https://www.linkedin.com/in/djschoonover/'} target={'_blank'}>
                                            <img src={'../../images/social-icons/linkedin-logo.png'} alt={'linkedin'} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ap-team-card-cont">
                            <div className="ap-team-card">
                                <div className="ap-team-img" id="reza">
                                </div>
                                <div className="ap-team-job">
                                    <div>
                                        <h4>Reza Jafery</h4>    
                                    </div>
                                    <div>
                                        <h5>Director of Content</h5>
                                    </div>
                                    <div className="ap-team-social-links">
                                        <Link to={'https://www.linkedin.com/in/reza-jafery-05a44384/'} target={'_blank'}>
                                            <img src={'../../images/social-icons/linkedin-logo.png'} alt={'linkedin'} />
                                        </Link>
                                        <Link to={'https://twitter.com/RezaJafery'} target={'_blank'}>
                                            <img src={'../../images/social-icons/twitter-sign.png'} alt={'twitter'} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ap-team-card-cont">
                            <div className="ap-team-card">
                                <div className="ap-team-img" id="elton">
                                </div>
                                <div className="ap-team-job">
                                    <div>
                                        <h4>Elton Hsu</h4>
                                    </div>
                                    <div>
                                        <h5>Senior Lead Developer</h5>
                                    </div>
                                    <div className="ap-team-social-links">
                                        <Link to={'https://www.linkedin.com/in/elthsu'} target={'_blank'}>
                                            <img src={'../../images/social-icons/linkedin-logo.png'} alt={'linkedin'} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
               </div>
            
                <div className="serv-page-cs">
                    <ContactForm />
                </div>
           </div>
        </div>
        
       <div>
        <Footer />
       </div>
      </div>
     );
  }
}

export default AboutPage;