import React, { useEffect } from 'react';
import "../Portfolio/Portfolio.scss";
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Portfolio = () => {

useEffect(() => {

if(window.innerWidth >= 1025) {
  gsap.to(document.querySelector(".s4-svg-cont"), {
    backgroundPosition:`100% 100%`,
    ease: "none",
    duration: 2,
    scrollTrigger: {
      trigger: "#portfolio-trig",
      // markers: true,
      start: document.querySelector(".intro").clientWidth + document.querySelector(".about").clientWidth + document.querySelector(".services").clientWidth,
      end: document.querySelector(".intro").clientWidth + document.querySelector(".about").clientWidth + document.querySelector(".services").clientWidth + document.querySelector(".portfolio").clientWidth,
      scrub: true
    }
  });
  
  gsap.to(document.querySelector(".s4-skew"), {
    backgroundPosition:`100% 100%`,
    ease: "none",
    x: -50,
    duration: 2,
    scrollTrigger: {
      trigger: "#portfolio-trig",
      // markers: true,
      start: document.querySelector(".intro").clientWidth + document.querySelector(".about").clientWidth + document.querySelector(".services").clientWidth,
      end: document.querySelector(".intro").clientWidth + document.querySelector(".about").clientWidth + document.querySelector(".services").clientWidth + document.querySelector(".portfolio").clientWidth,
      scrub: true
    }
  });
  } else {
    gsap.to(document.querySelector(".s4-svg-cont"), {
      backgroundPosition:`100% 100%`,
      ease: "none",
      duration: 2,
      scrollTrigger: {
        trigger: "#portfolio-trig",
        // markers: true,
        start: "center center",
        end: "+=700",
        scrub: true
      }
    });
  }
}, []);

    return (
     <div className={"portfolio horizontal-inner-in"} id={"portfolio-trig"}>
       <div className={"s4-cont"}>
        <div className={"s4-skew"}>
        </div>
         <div className={"s4-txt-cont"}>
           <div className={"s4-section-head"}>
            <h3>Portfolio</h3>
           </div>
            <div className={"s4-subtext"}>
              <p className={"s4-subhead"}>WE TAKE PRIDE IN DELIVERING RESULTS</p>
              <p className={"s4-text"}>Our case studies speak for themselves. Check out how we’ve created sustainable success for businesses just like yours.</p>
              <a href="/portfolio" className={"s4-exit-btn"}>
                  <span>Explore</span>
              </a>
            </div>
         </div>
        <div className={"s4-right-cont"}>
          <div className={"s4-svg-cont"}>
            <div className={"s4-svg-mask"}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 34">
                <polygon fill="currentColor"
                    points="9.51 0 18.74 15.97 15.53 21.55 6.29 5.57 9.51 0 0 0 0 32 9.49 32 0.26 16.02 3.47 10.45 12.71 26.43 9.49 32 19 32 19 0 9.51 0">
                </polygon>
              </svg>
            </div>
          </div>
        </div>
       </div>
    </div>
    );
  }

export default Portfolio;