import React from "react";
import "../Blog/Blog.scss";
import { Link } from "react-router-dom";
import { TimelineMax, Elastic, Linear } from "gsap/all";


class Blog extends React.Component {

  componentDidMount() {
    let btnTimeline5 = new TimelineMax();
    let btnTimeline6 = new TimelineMax();
    let btnTimeline7 = new TimelineMax();
    let btnTimeline8 = new TimelineMax();
    let lm5 = document.getElementById("aniBtn5");
    let lm6 = document.getElementById("aniBtn6");
    let lm7 = document.getElementById("aniBtn7");
    let lm8 = document.getElementById("aniBtn8");
   
   // Button One
   lm5.addEventListener("mouseenter", animateBtn5);
   lm5.addEventListener("mouseleave", animateBtn5);
    
    btnTimeline5.to(lm5.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline5.to(lm5, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline5.reversed(true);
   
    function animateBtn5() {
     btnTimeline5.reversed(!btnTimeline5.reversed());
    }
    // End Button One
   
   // Button Two
    lm6.addEventListener("mouseenter", animateBtn6);
    lm6.addEventListener("mouseleave", animateBtn6);
     
     btnTimeline6.to(lm6.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
     btnTimeline6.to(lm6, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
     btnTimeline6.reversed(true);
    
     function animateBtn6() {
      btnTimeline6.reversed(!btnTimeline6.reversed());
     }
    //End Button 2
   
    // Button Three
    lm7.addEventListener("mouseenter", animateBtn7);
    lm7.addEventListener("mouseleave", animateBtn7);
     
     btnTimeline7.to(lm7.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
     btnTimeline7.to(lm7, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
     btnTimeline7.reversed(true);
    
     function animateBtn7() {
      btnTimeline7.reversed(!btnTimeline7.reversed());
     }
    //End Button 3
   
     // Button Four
     lm8.addEventListener("mouseenter", animateBtn8);
     lm8.addEventListener("mouseleave", animateBtn8);
      
      btnTimeline8.to(lm8.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
      btnTimeline8.to(lm8, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
      btnTimeline8.reversed(true);
     
      function animateBtn8() {
       btnTimeline8.reversed(!btnTimeline8.reversed());
      }
     //End Button 4
  
   
   }

  render() {
    return (
      <div className={"blog horizontal-inner-in"}>
        <div className={"blog-cont"}>
         <div className={"blog-section-head"}>
            <Link to="/blog">
              <h3>Blog</h3>
            </Link>
          </div>
         <div className={"blog-card-cont"}>


          <div className={"blog-card-new"}>
            <div id={"blogTopFour"}>
             <div className={"blog-title-new"}>
               <h2>4 Questions a White Paper Needs to Answer</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>IEO White Papers</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>There are several necessary components to a successful token sale or ICO, but if you cant succinctly answer these 4 questions, stop.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/4-questions-a-white-paper-needs-to-answer"}>
                 <svg id={"aniBtn8"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>




          <div className={"blog-card-new"}>
            <div id={"blogTopThree"}>
             <div className={"blog-title-new"}>
               <h2>A Framework for Writing That Resonates</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>SEO</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Sales Funnels</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Copy Writing</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>Using the 5 W’s to master content, sales funnels, copywriting, advertising, and more.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/a-framework-for-writing-that-resonates"}>
                 <svg id={"aniBtn7"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>

          

          <div className={"blog-card-new"}>
            <div id={"blogTopTwo"}>
             <div className={"blog-title-new"}>
               <h2>How to Consistently Rank Articles on the First Page of Google Organically</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
            <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>SEO</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Content Tips</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Branding</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>I used to think of myself as a digital marketer. Little did I know, my marketing mind was holding back my content.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/how-to-consistently-rank-articles-on-the-first-page-of-google-organically"}>
                 <svg id={"aniBtn6"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>

        
        

        <div className={"blog-card-new"}>
            <div id={"blogTopOne"}>
             <div className={"blog-title-new"}>
               <h2>Top ICO’s Rated by White Paper Complexity</h2>
               <div className={"auth-img-new"}>
               {/* <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} /> */}
                <h3>by Reza Jaffery</h3>
               </div>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"blog-categories-new"}>
              <div className={"blog-tag-new"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>IEO Whitepapers</span></h3>
              </div>
              <div className={"blog-tag-new"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              
            </div>
            <div className={"blog-except-new"}>
              <p>Studying the correlation between the readability of white papers and the money raised.</p>
            </div>
            <div className={"blog-read-more-new"}>
                <Link to={"/top-icos-rated-by-white-paper-complexity"}>
                 <svg id={"aniBtn5"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
            </div>
            
          </div>


           {/* <div className={"blog-card"}>
            <div className={"blog-top"}>
             <div className={"blog-img"}>
              <img src={"../../../images/blog-imgs/blogTwo/how-well-do-americans-read.png"} alt={"how-well-americans-read"}></img>
             </div>
             <div className={"blog-title"}>
               <h2>Top ICO’s Rated by White Paper Complexity</h2>
               <h3>by Reza Jaffery</h3>
             </div>
            </div>
            <div className={"blog-categories"}>
              <div className={"blog-tag"}>
                <h3><span>Content Writing</span></h3>
              </div>
              <div className={"blog-tag"}>
                <h3><span>IEO Whitepapers</span></h3>
              </div>
              <div className={"blog-tag"}>
                <h3><span>Cryptocurrency</span></h3>
              </div>
              
            </div>
            <div className={"blog-except"}>
              <p>Studying the correlation between the readability of white papers and the money raised.</p>
            </div>
            <div className={"blog-read-more"}>
                <Link to={"/top-icos-rated-by-white-paper-complexity"}>
                 <svg id={"aniBtn6"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Read More</text>
                </svg>
                </Link>
            </div>
          </div> */}

          </div>
        </div>
      </div>
    );
  }
}

export default Blog;