import React from "react";

function Palette() {

    return (
        <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="palette" className="svg-inline--fa fa-palette fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <title>Group 3</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-3" transform="translate(0.002207, 0.473523)" fill-rule="nonzero">
            <path d="M204.287793,5.02647683 C104.887793,24.4264768 24.7877926,104.316477 5.18779259,203.426477 C-31.8122074,390.426477 136.887793,529.816477 263.987793,510.116477 C305.187793,503.716477 325.387793,455.526477 306.487793,418.426477 C283.387793,373.026477 316.387793,320.026477 367.387793,320.026477 L447.087793,320.026477 C482.887793,320.026477 511.887793,290.426477 511.987793,254.716477 C511.487793,97.1564768 368.087793,-26.8435232 204.287793,5.02647683 Z M95.9977926,320.026477 C78.3246806,320.026477 63.9977926,305.699589 63.9977926,288.026477 C63.9977926,270.353365 78.3246806,256.026477 95.9977926,256.026477 C113.670905,256.026477 127.997793,270.353365 127.997793,288.026477 C127.997793,305.699589 113.670905,320.026477 95.9977926,320.026477 Z M127.997793,192.026477 C110.324681,192.026477 95.9977926,177.699589 95.9977926,160.026477 C95.9977926,142.353365 110.324681,128.026477 127.997793,128.026477 C145.670905,128.026477 159.997793,142.353365 159.997793,160.026477 C159.997793,177.699589 145.670905,192.026477 127.997793,192.026477 Z M255.997793,128.026477 C238.324681,128.026477 223.997793,113.699589 223.997793,96.0264768 C223.997793,78.3533648 238.324681,64.0264768 255.997793,64.0264768 C273.670905,64.0264768 287.997793,78.3533648 287.997793,96.0264768 C287.997793,113.699589 273.670905,128.026477 255.997793,128.026477 Z M383.997793,192.026477 C366.324681,192.026477 351.997793,177.699589 351.997793,160.026477 C351.997793,142.353365 366.324681,128.026477 383.997793,128.026477 C401.670905,128.026477 415.997793,142.353365 415.997793,160.026477 C415.997793,177.699589 401.670905,192.026477 383.997793,192.026477 Z" id="Shape" fill="#FFFFFF"></path>
            <path d="M95.9977926,256.026477 C78.3246806,256.026477 63.9977926,270.353365 63.9977926,288.026477 C63.9977926,305.699589 78.3246806,320.026477 95.9977926,320.026477 C113.670905,320.026477 127.997793,305.699589 127.997793,288.026477 C127.997793,270.353365 113.670905,256.026477 95.9977926,256.026477 Z" id="Path" fill="#2FCC19"></path>
            <path d="M127.997793,128.026477 C110.324681,128.026477 95.9977926,142.353365 95.9977926,160.026477 C95.9977926,177.699589 110.324681,192.026477 127.997793,192.026477 C145.670905,192.026477 159.997793,177.699589 159.997793,160.026477 C159.997793,142.353365 145.670905,128.026477 127.997793,128.026477 Z" id="Path" fill="#00E7F7"></path>
            <path d="M255.997793,64.0264768 C238.324681,64.0264768 223.997793,78.3533648 223.997793,96.0264768 C223.997793,113.699589 238.324681,128.026477 255.997793,128.026477 C273.670905,128.026477 287.997793,113.699589 287.997793,96.0264768 C287.997793,78.3533648 273.670905,64.0264768 255.997793,64.0264768 L255.997793,64.0264768 Z" id="Path" fill="#FF3800"></path>
            <path d="M383.997793,128.026477 C366.324681,128.026477 351.997793,142.353365 351.997793,160.026477 C351.997793,177.699589 366.324681,192.026477 383.997793,192.026477 C401.670905,192.026477 415.997793,177.699589 415.997793,160.026477 C415.997793,142.353365 401.670905,128.026477 383.997793,128.026477 Z" id="Path" fill="#A3A3A3"></path>
        </g>
    </g>
</svg>
    );
  }

export default Palette;