import React from 'react';
import { Link } from "react-router-dom";
import "../Footer/Footer.scss";

const Footer = () => {

    return (
      <div className={"footer"}>
        <div className={"footer-logo-cont"}>
          <Link to={"/"}>
           <img src="../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png" alt="bdl-black-logo"/>
          </Link>
        </div>
        <div className={"footer-copyright"}>
          <span>© Bespoke Design Labs {(new Date().getFullYear())}, All Rights Reserved</span>
        </div>
      </div>
    );
  }

export default Footer;