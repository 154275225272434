import React, {useEffect} from "react";
import "./PortfolioPage.scss";
import Burger from "../Burger";
// import Abacus from "../../Svgs/Abacus";
// import Bitcoin from "../../Svgs/Bitcoin";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { TimelineMax, Elastic, Linear } from "gsap/all";
import fitnessApp from "../../pdf/fitness-app-cs.pdf";
import visualizationApp from "../../pdf/visualization-app.pdf";
import tarotCards from "../../pdf/tarot-cards-case-study.pdf";
import appDl from "../../pdf/app-download-case-study.pdf";
import ebates from "../../pdf/ebates-case-study.pdf";
import justFab from "../../pdf/justfab-case-study.pdf";
import eComm from "../../pdf/ecommerce-design-case-study.pdf";


const PortfolioPage = () => {

  useEffect(() => {
    const btnTimeline16 = new TimelineMax();
    const btnTimeline17 = new TimelineMax();
    const btnTimeline18 = new TimelineMax();
    const btnTimeline19 = new TimelineMax();
    const btnTimeline20 = new TimelineMax();
    const btnTimeline21 = new TimelineMax();
    const btnTimeline22 = new TimelineMax();

    const lm16 = document.getElementById("aniBtn16");
    const lm17 = document.getElementById("aniBtn17");
    const lm18 = document.getElementById("aniBtn18");
    const lm19 = document.getElementById("aniBtn19");
    const lm20 = document.getElementById("aniBtn20");
    const lm21 = document.getElementById("aniBtn21");
    const lm22 = document.getElementById("aniBtn22");
  
   
   // Button 16
   lm16.addEventListener("mouseenter", animateBtn16);
   lm16.addEventListener("mouseleave", animateBtn16);
    
    btnTimeline16.to(lm16.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline16.to(lm16, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline16.reversed(true);
   
    function animateBtn16() {
     btnTimeline16.reversed(!btnTimeline16.reversed());
    }
    // End Button 16



    // Button 17
   lm17.addEventListener("mouseenter", animateBtn17);
   lm17.addEventListener("mouseleave", animateBtn17);
    
    btnTimeline17.to(lm17.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline17.to(lm17, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline17.reversed(true);
   
    function animateBtn17() {
     btnTimeline17.reversed(!btnTimeline17.reversed());
    }
    // End Button 17

    // Button 18
   lm18.addEventListener("mouseenter", animateBtn18);
   lm18.addEventListener("mouseleave", animateBtn18);
    
    btnTimeline18.to(lm18.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline18.to(lm18, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline18.reversed(true);
   
    function animateBtn18() {
     btnTimeline18.reversed(!btnTimeline18.reversed());
    }
    // End Button 18


     // Button 19
   lm19.addEventListener("mouseenter", animateBtn19);
   lm19.addEventListener("mouseleave", animateBtn19);
    
    btnTimeline19.to(lm19.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline19.to(lm19, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline19.reversed(true);
   
    function animateBtn19() {
     btnTimeline19.reversed(!btnTimeline19.reversed());
    }
    // End Button 19


     // Button 20
   lm20.addEventListener("mouseenter", animateBtn20);
   lm20.addEventListener("mouseleave", animateBtn20);
    
    btnTimeline20.to(lm20.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline20.to(lm20, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline20.reversed(true);
   
    function animateBtn20() {
     btnTimeline20.reversed(!btnTimeline20.reversed());
    }
    // End Button 20

      // Button 21
   lm21.addEventListener("mouseenter", animateBtn21);
   lm21.addEventListener("mouseleave", animateBtn21);
    
    btnTimeline21.to(lm21.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline21.to(lm21, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline21.reversed(true);
   
    function animateBtn21() {
     btnTimeline21.reversed(!btnTimeline21.reversed());
    }
    // End Button 21

      // Button 22
   lm22.addEventListener("mouseenter", animateBtn22);
   lm22.addEventListener("mouseleave", animateBtn22);
    
    btnTimeline22.to(lm22.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline22.to(lm22, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline22.reversed(true);
   
    function animateBtn22() {
     btnTimeline22.reversed(!btnTimeline22.reversed());
    }
    // End Button 22
   
  });

    return (
      <div className={"portfolio-page"}>
       <div>
        <Burger />
       </div>
       <div className={"inner-serv-port"}>
         <div className={"serv-page-logo-cont"}>
           <Link to={"/"}>
            <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
           </Link>
         </div>
         <div className={"serv-page-title-cont"}>
          <h1>Portfolio</h1>
         </div>
       </div>


       <div className={"blog-page-card-cont"}>
         <div className={"bp-inner-wrapper"}>

         <div className={"blog-page-category-cont"}>
            <div className={"blog-page-category-title"}>
              <h3>Case Studies</h3>
              <hr />
            </div>
            <div className={"blog-page-cartegory-card-cont"}>


            <div className={"port-page-card-inner"}>
          <div className={"cs-page-card-new"}>
            <div id={"csTopSix"}>
             <div className={"blog-title-new"}>
               <h2>Visualization Mobile App Design & Development</h2>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"cs-categories"}>
             <div className={"cs-tag"}>
                <h3><span>React Native</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>UI/UX Design</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Mobile App Development</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Mobile App Design</span></h3>
              </div>
            </div>
            <div className={"cs-except-new"}>
              <p>This app was created to provide users with visualization exercises to help them improve their habits and achieve their goals, allow them to create journal entries to track their progress, and track their daily moods.</p>
            </div>
            <div className={"cs-dl-cont"}>
                <Link to={visualizationApp} target={"_blank"}>
                 <svg id={"aniBtn21"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Download</text>
                </svg>
                </Link>
            </div>
            </div>
          </div>
          </div>



          <div className={"port-page-card-inner"}>
          <div className={"cs-page-card-new"}>
            <div id={"csTopSeven"}>
             <div className={"blog-title-new"}>
               <h2>Fitness Mobile App Design & Development</h2>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"cs-categories"}>
              <div className={"cs-tag"}>
                <h3><span>React Native</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>UI/UX Design</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Mobile App Development</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Mobile App Design</span></h3>
              </div>
            </div>
            <div className={"cs-except-new"}>
              <p>This app was created to connect people with similar sports & fitness interests. The idea was to create a social hub for members to discover, participate, and stay in touch with people who have similar fitness interests and goals as themselves.</p>
            </div>
            <div className={"cs-dl-cont"}>
                <Link to={fitnessApp} target={"_blank"}>
                 <svg id={"aniBtn22"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Download</text>
                </svg>
                </Link>
            </div>
            </div>
          </div>
          </div>


            <div className={"port-page-card-inner"}>
          <div className={"cs-page-card-new"}>
            <div id={"csTopOne"}>
             <div className={"blog-title-new"}>
               <h2>Tarot Card - Media Buying</h2>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"cs-categories"}>
             <div className={"cs-tag"}>
                <h3><span>Media Buying</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Facebook</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Instagram</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Pinterest</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Social Media Marketing</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Conversion Rate Optimization</span></h3>
              </div>
            </div>
            <div className={"cs-except-new"}>
              <p>By using their existing data, BDL determined the ideal customer demographics and created copywriting, videos, and customer profiles to target accordingly. The campaign was structured into three key phases: Research, Sales Funnel Development, and Campaign Optimization.</p>
            </div>
            <div className={"cs-dl-cont"}>
                <Link to={tarotCards} target={"_blank"}>
                 <svg id={"aniBtn16"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Download</text>
                </svg>
                </Link>
            </div>
            </div>
          </div>
          </div>


          <div className={"port-page-card-inner"}>
          <div className={"cs-page-card-new"}>
            <div id={"csTopTwo"}>
             <div className={"blog-title-new"}>
               <h2>Ebates - Lead Generation</h2>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"cs-categories"}>
            <div className={"cs-tag"}>
                <h3><span>Conversion Rate Optimization</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Instagram</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Facebook</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Social Media Marketing</span></h3>
              </div>
             <div className={"cs-tag"}>
                <h3><span>Lead Generation</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Funnel Design</span></h3>
              </div>
              
            </div>
            <div className={"cs-except-new"}>
              <p>Through initial campaign testing, BDL established that we could achieve higher Lead-to-Sales ratios within a customers first visit to the site by promoting individual products and categories to specific audiences rather than developing campaigns focused on user acquisition and general discount-focused audiences.</p>
            </div>
            <div className={"cs-dl-cont"}>
                <Link to={ebates} target={"_blank"}>
                 <svg id={"aniBtn17"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Download</text>
                </svg>
                </Link>
            </div>
          </div>
          </div>
          </div>



          <div className={"port-page-card-inner"}>
          <div className={"cs-page-card-new"}>
            <div id={"csTopThree"}>
             <div className={"blog-title-new"}>
               <h2>JustFab & Fabletics - Media Buying</h2>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"cs-categories"}>
             <div className={"cs-tag"}>
                <h3><span>Media Buying</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Facebook</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Instagram</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Pinterest</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Social Media Marketing</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Funnel Design</span></h3>
              </div>
            </div>
            <div className={"cs-except-new"}>
              <p>The main component of the campaign was Parasocial interaction. BDL developed communities within the fashion and shoe industries to act as our medium of communication. To establish trust with the target audience we promoted industry-specific deals that included several different brands, instead of strictly promoting the JustFab brand.</p>
            </div>
            <div className={"cs-dl-cont"}>
                <Link to={justFab} target={"_blank"}>
                 <svg id={"aniBtn18"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Download</text>
                </svg>
                </Link>
            </div>
          </div>
          </div>
          </div>




          <div className={"port-page-card-inner"}>
          <div className={"cs-page-card-new"}>
            <div id={"csTopFour"}>
             <div className={"blog-title-new"}>
               <h2>Ibotta & Letgo - Mobile App Download</h2>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"cs-categories"}>
             <div className={"cs-tag"}>
                <h3><span>In App Advertising</span></h3>
             </div>
             <div className={"cs-tag"}>
                <h3><span>Mobile App Download</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Display Advertising</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>GeoTargeting</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Social Media Marketing</span></h3>
              </div>
            </div>
            <div className={"cs-except-new"}>
              <p>Our target locations were emerging markets for mobile applications, this meant there was a large population to target-- but only a small fraction of the population that had access to the technology and the capital to effectively use the application. As such, we completed extensive research on each country to determine targeting characteristics for each locale.</p>
            </div>
            <div className={"cs-dl-cont"}>
                <Link to={appDl} target={"_blank"}>
                 <svg id={"aniBtn19"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Download</text>
                </svg>
                </Link>
            </div>
          </div>
          </div>
          </div>




          <div className={"port-page-card-inner"}>
          <div className={"cs-page-card-new"}>
            <div id={"csTopFive"}>
             <div className={"blog-title-new"}>
               <h2>E Commerce Design</h2>
             </div>
            </div>
            <div className={"blog-card-btm-new"}>
            <div className={"cs-categories"}>
             <div className={"cs-tag"}>
                <h3><span>Conversion Rate Optimization</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>UI/UX</span></h3>
              </div>
             <div className={"cs-tag"}>
                <h3><span>Funnel Design</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>E Commerce Design</span></h3>
              </div>
              <div className={"cs-tag"}>
                <h3><span>Sales Funnels</span></h3>
              </div>
            </div>
            <div className={"cs-except-new"}>
              <p>BDL's experienced team of media buyers and copywriters create dynamic ad sets that are designed to draw attention specifically from targeted audiences. The design of our ad sets are fluid, based on performance, and we test dozens of interations of copy and creative to ensure the highest possible ROI.</p>
            </div>
            <div className={"cs-dl-cont"}>
                <Link to={eComm} target={"_blank"}>
                 <svg id={"aniBtn20"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Download</text>
                </svg>
                </Link>
            </div>
          </div>
          </div>
          </div>



            </div>
         </div>
         </div>
       </div>

       <div>
        <Footer />
       </div>
      </div>
    );
  }

export default PortfolioPage;