import React from 'react';
import Burger from "../Burger"
import Footer from "../Footer"
import "../BlogOne/BlogOne.scss";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    // EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    FacebookMessengerShareButton,
    // LinkedinIcon,
    // RedditIcon,
    // TelegramIcon,
    // TwitterIcon,
    FacebookShareButton
  } from "react-share";




const BlogThree =  () => {

    let location = useLocation();
    let currentUrl = "http://bdl.media" + location.pathname;
    let quote = "If traditional trading requires extreme discipline, crypto requires the mental fortitude of Jedi. The crypto market is more volatile than any market the world has ever seen. You need to be the emotional equal to Yoda meditating on top of a mountain during a meteor shower."
    let title = "3 Things Not to Do When Bitcoin is Going Down"
    let description = "Trading or investing in cryptocurrency is a psychological war against yourself. Stock traders often say that investing in traditional markets requires extreme mental discipline. That discipline is…"
    let image = "../../../images/blog-imgs/blogThree/knife.jpeg"
    let hashtag = "#bitcoin"


    return (
        <>
        <div>
            <Burger />
        </div>
        <div className={"blog-temp-container"}>
            <Helmet>
             <title>{title}</title>
             <meta charset="utf-8" />
             <meta http-equiv="X-UA-Compatible" content="IE=edge" />
             <meta name="csrf_token" content="" />
             <meta property="type" content="website" />
             <meta property="url" content={currentUrl} />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
             <meta name="msapplication-TileColor" content="#ffffff" />
             <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
             <meta name="theme-color" content="#ffffff" />
             <meta name="_token" content="" />
             <meta name="robots" content="noodp" />
             <meta property="title" content={title} />
             <meta property="quote" content={quote} />
             <meta name="description" content={description} />
             <meta property="image" content={image} />
             <meta property="og:locale" content="en_US" />
             <meta property="og:type" content="website" />
             <meta property="og:title" content={title} />
             <meta property="og:quote" content={quote} />
             <meta property="og:hashtag" content={hashtag} />
             <meta property="og:image" content={image} />
             <meta content="image/*" property="og:image:type" />
             <meta property="og:url" content={currentUrl} />
             <meta property="og:site_name" content="CampersTribe" />
             <meta property="og:description" content={description} /> 
            </Helmet>

           <div className={"blog-temp-container-inner"}>
           <div className={"blog-temp-title-header"}>
             <div className={"blog-temp-title-header-logo-cont"}>
                <Link to={"/"}>
                    <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
                </Link>
            </div>
            <div className={"blog-temp-head-title-cont"}>
             <h1>{title}</h1>
            </div>
            </div>
            <div className={"blog-temp-cont"}>
             <div className={"blog-temp-title-cont"}>
                <div className={"blog-temp-auth-cont"}>
                    <div className={"auth-info-cont"}>
                      <div className={"auth-img"}>
                        <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} />
                      </div>
                      <div className={"blog-temp-article-info"}>
                        <div className={"auth-name"}>
                            <span>by: </span>
                            <h3>Reza Jaffery</h3>
                        </div>
                        <div className={"date-time"}>
                            <span>May 27, 2018 - 6 minute Read</span>
                        </div>
                      </div>

                    </div>
                    <div className={"blog-temp-share-cont"}>
                        <FacebookShareButton 
                            url={currentUrl}
                            quote={quote}
                            hashtag={hashtag}
                            className={"share-btns"}>
                            <FacebookIcon size={36} round={true} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton>

                        </FacebookMessengerShareButton>

                    </div>
                    
                </div>
             </div>
             <div className={"blog-temp-body"}>
                <div>
                    <p>Trading or investing in cryptocurrency is a psychological war against yourself. Stock traders often say that investing in traditional markets requires extreme mental discipline. That discipline is how they control themselves when the market is volatile. How they stop making stupid decisions and keep on track with their strategy.</p>
                </div>
                <div>
                    <h4><span>If traditional trading requires extreme discipline, crypto requires the mental fortitude of Jedi. The crypto market is more volatile than any market the world has ever seen. You need to be the emotional equal to Yoda meditating on top of a mountain during a meteor shower.</span></h4>
                </div>
                <div>
                    <p><strong>Yes the world might be ending, but it’s all good, you’re hanging out on your mountain top.</strong></p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogThree/fortress-of-solitude.jpeg"} alt={"fortress"} />
                </div>
                <div>
                    <p><i>Your fortress of solitude.</i></p>
                </div>
                <div>
                    <p>I’ve been in this space for a few years now. I started off as an investor but fell deeper down the rabbit hole. I work with a blockchain marketing agency, and recently joined the team of a <Link to={"https://akoin.io"} target={"_blank"}>project I fell in love with</Link>. I like to think this gives me a comprehensive understanding of fundamentals at play.</p>
                </div>
                <div>
                    <p>What this understanding of the players involved in this market has given me is..</p>
                </div>
                <div>
                    <p>Well, the knowledge that this market is almost completely sentiment driven. Which means it doesn’t always make sense. Crypto has more retail investors than any other market. You can see this if you look at the Bitcoin price chart next to a chart of Google search trends for the word “Bitcoin”. The charts are pretty similar, it would seem, The more people that google Bitcoin, the higher the price goes. The Search Volume graph seems more smooth because less data points were used to plot the graph.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogThree/bitcoin-chart-1.png"} alt={"bitcoin-chart-1"} />
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogThree/bitcoin-chart-2.png"} alt={"bitcoin-chart-2"} />
                </div>
                <div>
                    <p>The search volume actually starts to pick up just before the price goes up.</p>
                </div>
                <div>
                    <p>When a market is sentiment driven, price movement is driven by emotion. To come out on top in a market driven by emotion, you have to remove emotion from your trading.</p>
                </div>
                <div>
                    <p>The reason I’m telling you all this is so you understand that it’s not always about what you do, but what you don’t do. It’s what you refrain from when exhibiting that mental fortitude we talked about. When the price of Bitcoin goes down, and you watch all of your cryptocurrency holdings lose their value, it’s hard to remain calm. When the market gets bloody, you might even be wondering if Bitcoin will ever go back up again. I don’t have a crystal ball but can say with a decent amount of confidence that it will return to its former glory.</p>
                </div>
                <div>
                    <h2>Don’t try to catch the exact bottom.</h2>
                </div>
                <div>
                    <p>If you’re lucky enough to have some of your investment capital in fiat or BTC when the market takes a tumble, it can be a good time to construct your portfolio. I like to think of market corrections as “Crypto Flash Sales”. I almost always keep some fiat or BTC tucked away in case of a large dip in the market — but my obsession with catching the bottom of a downtrend has made me lose out on some big wins.</p>
                </div>
                <div>
                    <p>When you’re staring at a coins chart, watching it plummet in price, wondering when it will stop. You’re trying to catch the bottom. “Catching the bottom” means you’re attempting to enter into a trade at a bottom of a downtrend. It’s extremely difficult to catch the exact bottom on a trade. So difficult that it’s often called, “catching a falling knife”.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogThree/knife.jpeg"} alt={"knife-img"} />
                </div>
                <div>
                    <p>If you’re constantly trying to catch the exact bottom, chances are you’re going to miss out on trades. I can’t tell you how many times I’ve waited, and waited, only to have a trend reverse before I got in. I’ve missed out on far more than I’ve gained by trying to be a perfectionist with my trade entries. Sometimes, if you’re planning on entering a trade, it’s better to just get in near the bottom rather than wait.</p>
                </div>
                <div>
                    <h2>Don’t sell your coins for ones that are going up.</h2>
                </div>
                <div>
                    <p>Everyone’s done it. You don’t have to be ashamed. It’s only human. We’ve all sold the bottom of a downtrend only to see it immediately reverse and shoot upwards.</p>
                </div>
                <div>
                    <p>It seems like every time I abandon ship on a coin to FOMO into another one, the coin I sell goes up. FOMO stands for Fear of Missing Out, it’s a common fear that causes a lot of bad decisions in trading.</p>
                </div>
                <div>
                    <p><i>Let me paint you a picture.</i></p>
                </div>
                <div>
                    <p>You buy $XYZ at $0.25 cents, you’ve done your due diligence, studied the charts for a while, and you’re ready. You didn’t even try to catch the bottom, you know this is a long term hold so you’re fine with your entry. You’re cool as a cucumber.</p>
                </div>
                <div>
                    <p>Two weeks pass, $XYZ is still at $0.25 cents. You’re starting to question your beliefs, sense of logic, even your reality. You start getting into arguments with old people at Denny’s about what “money” means. The longer your bag sits firmly on the ground and not the “moon” , the more bitter you become.</p>
                </div>
                <div>
                    <p>Two more weeks pass. $XYZ is at $0.20 cents. You’re friend John who just got into crypto last week told you about some sh*tcoin called $ABC. You laugh at him for even considering any coin besides $XYZ. Then, a mere 6 hours after John buys $ABC it skyrockets. It goes up 70%, John thinks he’s the king of crypto. He offers you advice, knowing that your coin is still in the gutter.</p>
                </div>
                <div>
                    <p>It’s at this fateful moment that you lose sight of what is most important.</p>
                </div>
                <div>
                    <p>Your strategy.</p>
                </div>
                <div>
                    <p><i>“Screw it!”</i>, you exclaim.</p>
                </div>
                <div>
                    <p>“This market doesn’t make sense if it rewards idiots like John”, you think.</p>
                </div>
                <div>
                    <p>After all, he’s only been trading for 12 hours and he’s up 80%, what kind of sick joke is this. He already got that promotion over you, and now he’s getting all the good trades?!</p>
                </div>
                <div>
                    <p>The next day John’s stupid $ABC coin goes up 150%. You stare out your window, not at the beautiful LA skyline, but at the street below. Wondering if jumping out the window would hurt less than watching John tell everyone in the office what “Hodl” means as he waves around his hardware wallet. You get home, open up your exchange, and say goodbye to $XYZ. You sell all your holdings, at a loss, and move your capital into $ABC.</p>
                </div>
                <div>
                    <p>The next day, $XYZ goes up 800%. John calls you, excited, “Congrats! I saw $XYZ mooned today, oh man I wish I got out of $ABC while I was ahead, It’s down now, practically the same as when I got in. I should have listened to you and bought $XYZ”.</p>
                </div>
                <div>
                    <p>Don’t be that person. I’ve been that person, it sucks.</p>
                </div>
                <div>
                    <h2>Don’t stare at the charts all day.</h2>
                </div>
                <div>
                    <p>Believe it or not, you can’t force a chart to go up or down with Jedi Mind Tricks.</p>
                </div>
                <div>
                    <div style={{width:"100%",height:"0",paddingBottom:"43%",position: "relative"}}>
                    <iframe title="giphy-droids" src={"https://giphy.com/embed/3o84sF21zQYacFcl68"} width={"100%"} height={"100%"} style={{position: "absolute", frameBorder: "0"}} className={"giphy-embed"} allowFullScreen></iframe></div>
                </div>
                <div>
                    <p>Trust me, I’ve tried. When I first started trading I used to spend countless hours “charting”. Looking back, if I’m being honest with myself, a lot of those hours were wasted. Sure, I spent a lot of time actually studying and applying what I learned to my trading, but I also spent a lot of time aimlessly staring at a computer screen for hours on end.</p>
                </div>
                <div>
                    <p>I learned that the majority of my mistakes where made in moments like those. When I wasn’t being productive, I was needlessly monitoring my holdings like a hawk. It made me more emotional, and it made me overtrade. Two things I now know to avoid at all cost.</p>
                </div>
                <div>
                    <p>Sometimes the best thing you can do, is set a limit-order, and walk away. Trust your strategy, and always remember — <Link to={"https://hackernoon.com/the-cryptocurrency-commandments-part-1-investment-best-practices-4ba185258511"} target={"_blank"}>if you’re not trading with a strategy, you’re gambling.</Link></p>
                </div>
                <div>
                    <p><strong>Want to talk to Reza about Crypto Trading?<br></br>Shoot him an email <Link>here</Link></strong>
                    </p>
                </div>







                
             </div>

            </div>
          </div>
        </div>
        <div>
            <Footer />
        </div>
        </>
    );
}

export default BlogThree;