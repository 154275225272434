import React from 'react';
import Burger from "../Burger"
import Footer from "../Footer"
import "../BlogOne/BlogOne.scss";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    // EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    FacebookMessengerShareButton,
    // LinkedinIcon,
    // RedditIcon,
    // TelegramIcon,
    // TwitterIcon,
    FacebookShareButton
  } from "react-share";




const BlogTwo =  () => {

    let location = useLocation();
    let currentUrl = "http://bdl.media" + location.pathname;
    let quote = "If you inspect other genres, you’ll find that as a rule of thumb; writing is more successful the more simple it is. (source) The Hemingway Editor scores your writing based on how simple it is to…"
    let title = "Top ICO’s Rated by White Paper Complexity"
    let description = "Studying the correlation between the readability of white papers and the money raised."
    let image = "../../../images/blog-imgs/blogTwo/how-well-do-americans-read.png"
    let hashtag = "#ico"


    return (
        <>
        <div>
            <Burger />
        </div>
        <div className={"blog-temp-container"}>
            <Helmet>
             <title>{title}</title>
             <meta charset="utf-8" />
             <meta http-equiv="X-UA-Compatible" content="IE=edge" />
             <meta name="csrf_token" content="" />
             <meta property="type" content="website" />
             <meta property="url" content={currentUrl} />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
             <meta name="msapplication-TileColor" content="#ffffff" />
             <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
             <meta name="theme-color" content="#ffffff" />
             <meta name="_token" content="" />
             <meta name="robots" content="noodp" />
             <meta property="title" content={title} />
             <meta property="quote" content={quote} />
             <meta name="description" content={description} />
             <meta property="image" content={image} />
             <meta property="og:locale" content="en_US" />
             <meta property="og:type" content="website" />
             <meta property="og:title" content={title} />
             <meta property="og:quote" content={quote} />
             <meta property="og:hashtag" content={hashtag} />
             <meta property="og:image" content={image} />
             <meta content="image/*" property="og:image:type" />
             <meta property="og:url" content={currentUrl} />
             <meta property="og:site_name" content="CampersTribe" />
             <meta property="og:description" content={description} /> 
            </Helmet>

           <div className={"blog-temp-container-inner"}>
           <div className={"blog-temp-title-header"}>
             <div className={"blog-temp-title-header-logo-cont"}>
                <Link to={"/"}>
                    <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
                </Link>
            </div>
            <div className={"blog-temp-head-title-cont"}>
             <h1>{title}</h1>
            </div>
                </div>
            <div className={"blog-temp-cont"}>
             <div className={"blog-temp-title-cont"}>
                {/* <div>
                 <h1>4 Questions a White Paper Needs to Answer</h1>
                </div> */}
                <div className={"blog-temp-auth-cont"}>
                    <div className={"auth-info-cont"}>
                      <div className={"auth-img"}>
                        <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} />
                      </div>
                      <div className={"blog-temp-article-info"}>
                        <div className={"auth-name"}>
                            <span>by: </span>
                            <h3>Reza Jaffery</h3>
                        </div>
                        <div className={"date-time"}>
                            <span>February 22, 2018 - 7 minute Read</span>
                        </div>
                      </div>

                    </div>
                    <div className={"blog-temp-share-cont"}>
                        <FacebookShareButton 
                            url={currentUrl}
                            quote={quote}
                            hashtag={hashtag}
                            className={"share-btns"}>
                            <FacebookIcon size={36} round={true} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton>

                        </FacebookMessengerShareButton>

                    </div>
                    
                </div>
             </div>


             <div className={"blog-temp-body"}>
                <div>
                    <h4>Studying the correlation between the readability of white papers and the money raised.</h4>
                </div>
                <div>
                    <p>I recently discovered the <Link to={"http://hemingwayapp.com/"} target={"_blank"}>Hemingway Editor App</Link>, and it’s slowly changing how I view “good” writing.</p>
                </div>
                <div>
                    <h2>Hemingway’s most popular novels are written in a 4th-6th grade reading level.`</h2>
                </div>
                <div>
                    <p>If you inspect other genres, you’ll find that as a rule of thumb; writing is more successful the more simple it is. <Link to={"https://contently.com/2015/01/28/this-surprising-reading-level-analysis-will-change-the-way-you-write/"} target={"_blank"}>(source)</Link></p>
                </div>
                <div>
                    <p>The <Link to={"http://hemingwayapp.com/"} target={"_blank"}>Hemingway Editor</Link> scores your writing based on how simple it is to understand. Your score reflects the, “lowest education needed to understand your prose”.</p>
                </div>
                <div>
                    <p>They explain why you want to score as low as possible in this excerpt:</p>
                </div>
                <div>
                    <p><i>Writing that scores at a 15th grade level is not better than writing at an 8th grade level. In fact, a high grade level often means it is confusing and tedious for any reader. Worse, it’s likely filled with jargon. After all, unless you’re writing a textbook (and even then) you don’t want it to sound like a textbook.<Link to={"http://www.hemingwayapp.com/help.html"} target={"_blank"}>[source]</Link></i></p>
                </div>
                <div>
                    <p>Articles from GQ clock in around a 6th grade reading level. Whereas Buzzfeed articles — which are shared more than GQ articles — are at a 3rd grade level.</p>
                </div>
                <div>
                    <p>It makes sense when you think about the fact that <strong>only 50% of Americans can read at an 8th grade level.</strong> Even more sense when you notice a mere <strong>15–20% of Americans are able to comprehend writing at a 12th grade level.</strong></p>
                </div>
                <div>
                    <h4><span>If we want mass adoption of blockchain technology, we need to make crypto more simple.</span></h4>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogTwo/how-well-do-americans-read.png"} alt={"us-reader-charts"} />
                </div>
                <div>
                    <h2>Readability vs. Dollars Raised</h2>
                </div>
                <div>
                    <p>This made me wonder if there’s any correlation here. Between the readability of White Papers and dollars raised.</p>
                </div>
                <div>
                    <p><i>A “White Paper” is essentially a consumer facing Business Plan for Blockchain Projects. Most coins will allow potential investors to download their White Paper off their official website. White papers are also one of the first elements of a project you should look at when deciding if it’s a solid investment.</i></p>
                </div>
                <div>
                    <p>I’m not saying that one ICO is better than another based on their score. There are countless ingredients to the perfect <i>ICO recipe.</i></p>
                </div>
                <div>
                    <p>Yet, some ICO’s hide behind technical white papers and esoteric terminology. As if they can trick readers out of their pockets with fancy words.</p>
                </div>
                <div>
                    <p>A couple months ago, I embarked on a mission to determine “ICO Launch Best Practices” — ranging from white paper design to token supply. I work with BlockchainWarehouse and a part of what I do is vetting projects that approach us for support. We’re an accelerator for blockchain based projects, so as a crypto nerd it’s a pretty great gig. I get to help companies go from “napkin idea” all the way to their Token Generation Event, and have had the chance to meet some of the most talented individuals I’ve ever come across. Both on my team at BlockchainWarehouse, and with the companies we’ve helped launch.</p>
                </div>
                <div>
                    <p>I wanted to determine quantifiable metrics to help measure the chances of an ICO or Token Sales success, so I could apply them to the companies we work with. Less positively, I was also keen to learn if there was a method to the madness because, if there wasn’t, that might suggest that we are indeed in a bubble.</p>
                </div>
                <div>
                    <p>Let’s see how some of the biggest ICO’s rank with good ole’ Ernest.</p>
                </div>
                <div>
                    <p><strong>To ensure fairness in ranking; I removed all text from the white papers except for prose. No lists, team member names, mathematical equations, or anything that isn’t written in proper sentence structure.</strong></p>
                </div>
                <div>
                    <h2>The HemingwayApp Experiment</h2>
                    <h3>aka Operation Old Man in the Sea</h3>
                </div>
                <div>
                    <h2>Bitcoin</h2>
                    <p><strong>Score: </strong>4th Grade Reading Level</p>
                    <p><strong>Difficulty: </strong>20% of Sentences are hard or very hard to read.</p>
                    <p><strong>Length: </strong>9 Pages</p>
                </div>
                <div>
                    <p>I understand that Bitcoin never had an Initial Coin Offering (ICO). I’m including it here as a frame of reference. As the father of all cryptocurrency it’s important to remember where we came from.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogTwo/bitcoin-whitepaper.png"} alt={"bitcoin-whitepaper"} />
                </div>
                <div>
                    <p>The Bitcoin white paper is the shortest I’ve read. I was honestly taken a aback when I realized how brief it is.</p>
                </div>
                <div>
                    <h4><span>Bitcoin is disrupting 100 year old industries, and it explains itself in 9 pages. 8 if you don’t count the “References” page.</span></h4>
                </div>
                <div>
                    <p>Whether you’re a Bitcoin Maximilist or just someone trying to make a quick buck — you can’t deny that Bitcoin is the most successful cryptocurrency to date.</p>
                </div>
                <div>
                    <h2>FileCoin</h2>
                    <p><strong>Score: </strong>8th Grade Reading Level</p>
                    <p><strong>Amount Raised: </strong>$257 Million</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogTwo/filecoin-whitepaper.png"} alt={"filecoin-whitepaper"} />
                </div>
                <div>
                    <p><strong>Difficulty: </strong>39% of Sentences are hard or very hard to read.</p>
                    <p><strong>Length: </strong>36 Pages</p>
                </div>
                <div>
                    <h2>Tezos</h2>
                    <p><strong>Score: </strong>10th Grade Reading Level</p>
                    <p><strong>Amount Raised: </strong>$232 Million</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogTwo/tezos-whitepaper.png"} alt={"tezos-whitepaper"} />
                </div>
                <div>
                    <p><strong>Difficulty: </strong>43% of Sentences are hard or very hard to read.</p>
                    <p><strong>Length: </strong>20 Pages</p>
                </div>
                <div>
                    <h2>Bancor</h2>
                    <p><strong>Score: </strong>14th Grade Reading Level</p>
                    <p><strong>Amount Raised: </strong>$153 Million</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogTwo/bancor-whitepaper.png"} alt={"bancor-whitepaper"} />
                </div>
                <div>
                    <p><strong>Difficulty: </strong>67% of Sentences are hard or very hard to read.</p>
                    <p><strong>Length: </strong>22 Pages</p>
                </div>
                <div>
                    <h2>Status</h2>
                    <p><strong>Score: </strong>13th Grade Reading Level</p>
                    <p><strong>Amount Raised: </strong>$107 Million</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogTwo/the-status-network-whitepaper.png"} alt={"status-network-whitepaper"} />
                </div>
                <div>
                    <p><strong>Difficulty: </strong>65% of Sentences are hard or very hard to read.</p>
                    <p><strong>Length: </strong>35 Pages</p>
                </div>
                <div>
                    <h2>Takeaways</h2>
                </div>
                <div>
                    <p><strong>It would appear there is a loose correlation between simplicity and dollars raised.</strong></p>
                </div>
                <div>
                    <h2>Implications of Reading Difficulty</h2>
                </div>
                <div>
                    <p>In this <strong>limited sample size</strong>, the more simple a white paper — the more money raised. Filecoins’ white paper is technical at first glance. Upon further inspection, you’ll find that the technical aspects of it are mainly mathematical equations/proofs.</p>
                </div>
                <div>
                    <p>I assumed Tezos would score well due to the structure of its white paper. It’s beautiful with a simple workflow. I thought it would do better than Filecoin. I found the design of Filecoins white paper to be off-putting.</p>
                </div>
                <div>
                    <p>Yet, the prose surrounding those “complex equations” is simple to understand.</p>
                </div>
                <div>
                    <p>Bancor and Status, both use very esoteric language to explain their concepts. Keep in mind, these are both extremely successful ICO’s. Both within the top 2% of the largest raises in cryptocurrency.</p>
                </div>
                <div>
                    <p>Yet the difference between $100 million and $257 million is pretty large in any frame of reference.</p>
                </div>
                <div>
                    <h2>Simplicity is Velocity</h2>
                </div>
                <div>
                    <p>To reiterate, I don’t believe this limited research is truly indicative of a trend. I wrote this article to begin to understand what makes a successful ICO. The ICO’s I chose to rate are all top 10 ICO’s from 2017; in regards to the total dollars raised.</p>
                </div>
                <div>
                    <p>I want to learn what separates the good from the great.</p>
                </div>
                <div>
                    <p>In the current market environment, the quality of an ICO doesn’t matter as much as its ability to market itself.</p>
                </div>
                <div>
                    <p>I’ve seen some terrible ICO’s funded in the blink of an eye. I’ve also seen disruptive concepts, that could change the world, fizzle out because they didn’t get enough attention.</p>
                </div>
                <div>
                    <p>My goal is to determine how much each of the variables in the, “Perfect ICO Recipe”, should be weighted. To determine an effective grading method for upcoming ICO’s, that can be quantitatively tested.</p>
                </div>
                <div>
                    <p>I don’t believe that any of the projects above were launched poorly. I mentioned that some companies use technical language to hide the fact that they don’t know what they’re talking about. None of these projects fall into that category (to my knowledge).</p>
                </div>
                <div>
                    <p>Blockchain Technology needs to transition towards using prose that is easier to understand. If we want to achieve mass adoption, we have to make our reading material less damn intimidating!</p>
                </div>
                <div>
                    <p>I challenge all would-be-white-paper-writers and Blockchain CEO’s to take a stand and make crypto simple.</p>
                </div>
                <div>
                    <p>If you can’t explain your business model in a couple sentences to the average Joe or Jane on the street . What makes you think you’ll have the time to explain it to the people that count?</p>
                </div>
                <div>
                    <p>Every time I pitch a business to an investor I can tell if they’re interested within the first 30 seconds. Because it usually takes me 30 seconds or less to explain the concept.</p>
                </div>
                <div>
                    <p>White papers aren’t about “wowing” people with your technical knowledge. They’re about sharing your concept in a way so simple, readers experience the same “Aha” moment you had when you first thought of it.</p>
                </div>
                <div>
                    <p><strong>What do you believe makes a “Great” ICO? Comment below with your ICO grading criteria!</strong></p>
                </div>
                <div>
                    <h2>
                    In case you’re curious.
                    </h2>
                </div>
                <div>
                    <p>This article is written in a 6th grade reading level, and 8% of sentences are “hard to read”.</p>
                </div>
                <div>
                    <p>I’m coming for you Satoshi!</p>
                </div>












                
        
             </div>

            </div>
          </div>
        </div>
        <div>
            <Footer />
        </div>
        </>
    );
}

export default BlogTwo;