import React, { useEffect }from "react";
import "../About/About.scss";
import gsap from "gsap/all";
import { TimelineMax, Elastic, Linear } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const About = () => {

  useEffect(() => {
    if (window.innerWidth >= 1025) {
    let imgOne = document.getElementsByClassName("s2-img2-cont");
    gsap.to(imgOne, {
      x: -150,
      duration: 2,
      scrollTrigger: {
        trigger: "#about-trig",
        // markers: true,
        start: "+=" + document.querySelector(".about").offsetLeft,
        end: "+=" + document.querySelector(".about").offsetWidth,
        scrub: true
      }
    });

    let servSkew = document.getElementsByClassName("s3-skew");
    gsap.to(servSkew, {
      x: 200,
      duration: 2,
      scrollTrigger: {
        trigger: "#about-trig",
        // markers: true,
        start: "+=" + document.querySelector(".about").offsetLeft,
        end: "+=" + document.querySelector(".about").offsetWidth,
        scrub: true
      }
    });
  } else {
    let imgOne = document.getElementsByClassName("s2-img2-cont");
    let imgTwo = document.getElementsByClassName("s2-img1-cont");
    gsap.to(imgOne, {
      x: -75,
      duration: 5,
      scrollTrigger: {
        trigger: "#about-trig",
        scrub: true,
        start: "center center",
        end: "+=600"
      }
    });
    gsap.to(imgTwo, {
      x: 75,
      duration: 5,
      scrollTrigger: {
        trigger: "#about-trig",
        scrub: true,
        start: "center center",
        end: "+=600"
      }
    });
  }
   // Button 21
    const btnTimeline21 = new TimelineMax();
    const lm21 = document.getElementById("aniBtn21");
    lm21.addEventListener("mouseenter", animateBtn21);
    lm21.addEventListener("mouseleave", animateBtn21);
    
    btnTimeline21.to(lm21.children[0], 0.4, {attr:{width:100, fill:"#00e7f7"}, autoAlpha: 0.8, ease: Elastic.easeOut.config(1, 1)});
    btnTimeline21.to(lm21, 0.2, {fill:"#fff", ease:Linear.easeNone}, 0);
    btnTimeline21.reversed(true);
   
    function animateBtn21() {
     btnTimeline21.reversed(!btnTimeline21.reversed());
    }
    // End Button 21
    }, []);

    return (
    <div className={"about horizontal-inner-in"} id={"about-trig"}>
       <div className={"s2-cont"}>
        <div className={"s2-left-txt"}>
            <h2>We Create Fully Customized Digital Solutions</h2>
        </div>
        <div className={"s2-mid-text"}>
         <p>
           <span>We take a holistic approach with each and every client to optimize your current revenue streams and discover underutilized opportunities to help you reach new levels of success.</span> <br /><br />
           </p>
           <div className={"about-btn-cont"}>
                <Link to={"/about"}>
                 <svg id={"aniBtn21"} className={"s3-servBtn"} xmlns={"http://www.w3.org/2000/svg"} width={"100"} height={"50"} viewBox={"0 0 100 50"}>
                 <rect x={"0"} y={"0"} width={"30"} height={"30"} rx={"5"} ry={"5"} fill={"#00e7f7"} /> 
                 <text className={'btnTxt3'} transform={"translate(50 20)"} textAnchor={"middle"} fontSize={"12"} fill={"#000"}>Learn More</text>
                </svg>
                </Link>
            </div>
        </div>
        <div className={"s2-right-img"}>
            <div className={"s2-img1-cont"}>
              <img src={'../../../../images/about-imgs/training.jpg'} alt="img-2" />
            </div>
            <div className={"s2-img2-cont"}>
             <img src={'../../../../images/about-imgs/uiux.jpg'} alt={"img-1"} />
            </div>
        </div>
      </div>
    </div>
    ); 
   }

export default About;