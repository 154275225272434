import React from 'react';
import Burger from "../Burger"
import Footer from "../Footer"
import "../BlogOne/BlogOne.scss";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
    // EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    FacebookMessengerShareButton,
    // LinkedinIcon,
    // RedditIcon,
    // TelegramIcon,
    // TwitterIcon,
    FacebookShareButton
  } from "react-share";




const BlogSix =  () => {

    let location = useLocation();
    let currentUrl = "http://bdl.media" + location.pathname;
    let quote = "When the human brain is faced with new information, it attempts to categorize the information based on past experiences. In writing, we often categorize and bunch similar pieces of information…"
    let title = "A Framework for Writing That Resonates"
    let description = "Using the 5 W’s to master content, sales funnels, copywriting, advertising, and more."
    let image = "../../../images/blog-imgs/blogOne/btc-book.jpeg"
    let hashtag = "#contentWriting"


    return (
        <>
        <div>
            <Burger />
        </div>
        <div className={"blog-temp-container"}>
            <Helmet>
             <title>{title}</title>
             <meta charset="utf-8" />
             <meta http-equiv="X-UA-Compatible" content="IE=edge" />
             <meta name="csrf_token" content="" />
             <meta property="type" content="website" />
             <meta property="url" content={currentUrl} />
             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
             <meta name="msapplication-TileColor" content="#ffffff" />
             <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
             <meta name="theme-color" content="#ffffff" />
             <meta name="_token" content="" />
             <meta name="robots" content="noodp" />
             <meta property="title" content={title} />
             <meta property="quote" content={quote} />
             <meta name="description" content={description} />
             <meta property="image" content={image} />
             <meta property="og:locale" content="en_US" />
             <meta property="og:type" content="website" />
             <meta property="og:title" content={title} />
             <meta property="og:quote" content={quote} />
             <meta property="og:hashtag" content={hashtag} />
             <meta property="og:image" content={image} />
             <meta content="image/*" property="og:image:type" />
             <meta property="og:url" content={currentUrl} />
             <meta property="og:site_name" content="CampersTribe" />
             <meta property="og:description" content={description} /> 
            </Helmet>

           <div className={"blog-temp-container-inner"}>
           <div className={"blog-temp-title-header"}>
             <div className={"blog-temp-title-header-logo-cont"}>
                <Link to={"/"}>
                    <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
                </Link>
            </div>
            <div className={"blog-temp-head-title-cont"}>
             <h1>{title}</h1>
            </div>
                </div>
            <div className={"blog-temp-cont"}>
             <div className={"blog-temp-title-cont"}>
                {/* <div>
                 <h1>4 Questions a White Paper Needs to Answer</h1>
                </div> */}
                <div className={"blog-temp-auth-cont"}>
                    <div className={"auth-info-cont"}>
                      <div className={"auth-img"}>
                        <img src={"../../../images/blog-imgs/reza-jaffery.png"} alt={"reza-jaffery"} />
                      </div>
                      <div className={"blog-temp-article-info"}>
                        <div className={"auth-name"}>
                            <span>by: </span>
                            <h3>Reza Jaffery</h3>
                        </div>
                        <div className={"date-time"}>
                            <span>September 5, 2019 - 7 minute Read</span>
                        </div>
                      </div>

                    </div>
                    <div className={"blog-temp-share-cont"}>
                        <FacebookShareButton 
                            url={currentUrl}
                            quote={quote}
                            hashtag={hashtag}
                            className={"share-btns"}>
                            <FacebookIcon size={36} round={true} />
                        </FacebookShareButton>
                        <FacebookMessengerShareButton>

                        </FacebookMessengerShareButton>

                    </div>
                    
                </div>
             </div>
             <div className={"blog-temp-body"}>
                <div>
                    <img src={"../../../images/blog-imgs/blogSix/phone-picture.png"} alt={"phone"} />
                </div>
                <div>
                    <p>When the human brain is faced with new information, it attempts to categorize the information based on past experiences. In writing, we often categorize and bunch similar pieces of information together without realizing it. Our brains are trying to make it easier for a readers subconscious mind to digest and store the information we’re sharing.</p>
                </div>
                <div>
                    <p>When I first began my efforts to make a career out of digital marketing, I learned something that’s always stuck with me.</p>
                </div>
                <div>
                    <p>It wasn’t a secret growth hacking tactic or a plugin that could drastically reduce the cost-per-customer for eCommerce platforms. It was a way of thinking, a framework for making people care about whatever you want to talk about.</p>
                </div>
                <div>
                    <p>While I’m <Link to={"https://medium.com/swlh/balancing-creative-integrity-with-the-desire-to-succeed-as-a-writer-6ad335901813"} target={"_blank"}>no longer a digital marketer by trade</Link>, the lessons I learned still leak benefits into my writing life every once in a while.</p>
                </div>
                <div>
                    <p>If you’re in marketing there’re a couple of hard truths you have to swallow. The first of which is: you’re annoying.</p>
                </div>
                <div>
                    <p>Your ads are intrusive, no one wants to be advertised to, and only 0.001% of the times when you reach a customer will they say, “Wow, this is exactly what I needed, I’m so happy I was served this advertisement!”.</p>
                </div>
                <div>
                    <p><i>Sidenote: if you can figure out a platform or method for advertising to people strictly when they’re looking for your type of products or service, you’ve found your golden ticket.</i></p>
                </div>
                <div>
                    <h2>What are the 5 W’s?</h2>
                </div>
                <div>
                    <p>It’s really simple, deceivingly so. So simple in fact that I often don’t even bring this up when teaching others about marketing. It became so indoctrinated into my approach it seemed like common knowledge.</p>
                </div>
                <div>
                    <p>I stumbled upon this little gem somewhere in-between hundreds of online marketing courses, and although most of the content from those courses has been forgotten, I never forgot the 5 W’s.</p>
                </div>
                <div>
                    <p><Link to={"https://en.wikipedia.org/wiki/Aristotle"} target={"_blank"}>Aristotle’s</Link> <Link to={"https://en.wikipedia.org/wiki/Nicomachean_Ethics"} target={"_blank"}>Nicomachean Ethics</Link> outlines a methodology for determining if certain human acts were “ethical”. That same framework has been taken and used by people in project management, law, technical writing, copywriting, debate, and more.</p>
                </div>
                <div>
                    <p>The idea is that to best understand something, like the ethics of human behavior, you need to break it down into the Five W’s.</p>
                </div>
                <div>
                    <p><strong>Who</strong> is involved or affected by this?</p>
                </div>
                <div>
                    <p><strong>What</strong> is your product or service?</p>
                </div>
                <div>
                    <h4><span>Where can I (the customer) find this product, service, or information,</span></h4>
                </div>
                <div>
                    <p><strong>When</strong> Is there a time limit on this offer? When can I buy it? When is this happening?</p>
                </div>
                <div>
                    <p>You can apply the 5 W’s to essentially everything you do as a marketer.</p>
                </div>
                <div>
                    <p><i>“For in acts we must take note of who did it, by what aids or instruments he did it (with), what he did, where he did it, why he did it, how and when he did it.”<br />— Aristotle</i></p>
                </div>
                <div>
                    <h2>Why the 5 W’s work</h2>
                </div>
                <div>
                    <p>The definition of marketing, according to the <Link to={"https://www.ama.org/the-definition-of-marketing-what-is-marketing/"} target={"_blank"}>American Marketing Association</Link>, is, <i>“The activity, set of institutions, and processes for creating, communicating, delivering, and exchanging offerings that have value for customers, clients, partners, and society at large.”</i></p>
                </div>
                <div>
                    <p>Marketing, at its’ core, is about communicating.</p>
                </div>
                <div>
                    <p>In order to communicate a concept, you have to make another person comprehend it via the medium you’re communicating with them. That medium can be a Tweet, copy on a landing page, an article, a video — it doesn’t matter!</p>
                </div>
                <div>
                    <p>Regardless of how you’re communicating with someone, they need to understand what it is you’re talking about as quickly as possible if you’re going to get them to care about it.</p>
                </div>
                <div>
                    <p>It’s important that people care about your marketing because whether or not it’s a Facebook Ad or an email blast — it either needs to drive someone to act in that exact moment, or be memorable enough that when a person needs your product or service, they’ll think of you.</p>
                </div>
                <div>
                    <p>
                    When people try to learn new information, it initially lands in our short-term memory. There are plenty of theories about how information transitions from short-term to long-term memory, but an underlying concept in many of them is: we can retain information in short-term memory but simply reading/seeing it, but to commit something to long-term memory we need to <Link to={"https://www.tandfonline.com/doi/abs/10.1080/14640747308400320"} target={"_blank"}>comprehend it</Link>.
                    </p>
                </div>
                <div>
                    <p>I apply the 5 W’s to everything I do in marketing, it’s so ingrained into my writing style now that it happens on accident. Even this article (I’m just now noticing) follows that structure.</p>
                </div>
                <div>
                    <h3>So we now know…</h3>
                </div>
                <div>
                    <ol>
                        <li>To market is to sell a product or service.</li>
                        <li>To sell to someone you need to communicate with them.</li>
                        <li>Communication is only effective if people remember it (or care enough to act in that exact moment).</li>
                        <li>If you want people to remember your message they have to understand it.</li>
                        <li>Breaking information down into the framework of the 5 W’s makes that information easy to understand and retain.</li>
                    </ol>
                </div>
                <div>
                    <p>Let’s take a look at how you can actually apply the 5 W’s to something you’re working on now.</p>
                </div>
                <div>
                    <h2>Applying the 5 W’s</h2>
                </div>
                <div>
                    <p>I’m going to use a product I’m personally excited to illustrate the example; <Link to={"https://store.google.com/product/stadia?stadia&gclid=EAIaIQobChMI582Lzdqo5AIVkhx9Ch3vdgw8EAAYASAAEgLUBfD_BwE&srp=/product/stadia_founders_edition"} target={"_blank"}>Stadia.</Link></p>
                </div>
                <div>
                    <p>Stadia is a cloud-gaming platform that allows you to play video games at extremely high quality without actually owning any hardware.</p>
                </div>
                <div>
                    <p>“Stadia Founder’s Edition lets you instantly enjoy games in up to 4K on your TV1 without a console. You can also play across laptops, desktops, Pixel 3, and Pixel 3a2 with cross-screen early access starting from day one.” — Stadia</p>
                </div>
                <div>
                    <p>Granted, Stadia is being created by Google. Google has such a strong brand that they often don’t need to address the “Who”. Seeing the classic Google “G” in the corner is enough for customers to understand “Who” is publishing the content that Google does.</p>
                </div>
                <div>
                    <ul>
                        <li><p><strong>Who: </strong>Google is making a product for Gamers</p></li>
                        <li><p><strong>What: </strong></p>Stadia; a cloud gaming platform that allows users to play games at a quality that, until now, would require thousands of dollars of gaming equipment. Experience virtually zero wait-time to download games, and since Stadia is cloud-based, users can access it from any device, anywhere there’s an internet connection.</li>
                        <li><p><strong>Where: </strong>Online, exclusively through the Google Store</p></li>
                        <li><p><strong>When: </strong>November 2019</p></li>
                        <li><p><strong>Why: </strong>If you pre-order now, you can get access Stadia Founders edition with 3 free months of Stadia, and a buddy pass for a friend.</p></li>
                    </ul>
                </div>
                <div>
                    <p>If I was writing an advertisement or the website copy for Stadia, I’d start by getting this information together.</p>
                </div>
                <div>
                    <p>Then, I’d start writing the copy making sure that I’m addressing each of the 5 W’s.</p>
                </div>
                <div>
                    <p>Maybe something like this;</p>
                </div>
                <hr />
                <div>
                    <p>Welcome to Stadia by Google (who)</p>
                </div>
                <div>
                    <p><i>A cloud-gaming platform that allows users to play games at a quality that, until now, would require thousands of dollars of gaming equipment. Experience virtually zero wait-time to download games, and play on any device, anywhere (what).</i></p>
                </div>
                <div>
                    <p>Pre-Order today, exclusively in the Google Store (when/where)for three free months of Stadia Pro, and three months of Stadia Pro to give away to a friend with a Buddy Pass (why). The Founders Edition also entitles you to the exclusive Night Blue Stadia Controller, a Google Chromecast, and a Founders username — all for just $129 (why).</p>
                </div>
                <hr />
                <div>
                    <p>I’ve intentionally kept this pretty simplistic for the sake of the example. You don’t always have to spell out the 5 W’s for potential customers, you can imply the Who or Why without directly explaining them.</p>
                </div>
                <div>
                    <p>Medium writers do this often with their titles. We’ll put the Who and the Why in the title so readers know if they should care about it.</p>
                </div>
                <div>
                    <p>Here are two articles that popped up in my feed today.</p>
                </div>
                <div>
                    <img src={"../../../images/blog-imgs/blogSix/google-results.png"} alt={"google-results"} />
                </div>
                <div>
                    <p>Both of these address at least 2 of the 5 W’s in their title.</p>
                </div>
                <div>
                    <h2>Example 1: “How to Sell with Words, Even if You Suck at Writing”</h2>
                </div>
                <div>
                    <p><strong>What: </strong>How to sell with words</p>
                </div>
                <div>
                    <p><strong>Who: </strong>People who have to write for a living but don’t necessarily consider it a skill.</p>
                </div>
                <div>
                    <p>Barry effectively lets his readers know what his article is about, and who the target audience is. He’s not targeting skilled writers, but people who may need to develop their writing craft in order to do their jobs better.</p>
                </div>
                <div>
                    <p>In this case, the “Why” is essentially the article. He told readers what he wanted to say, and who it was addressed to, now he just needs to let them know why they should care.</p>
                </div>
                <hr />
                <div>
                    <h2>Example 2: “7 Psychological Reasons to Be More Playful as an Adult”</h2>
                </div>
                <div>
                    <p><strong>What: </strong>Psychological findings on why it’s good to be playful</p>
                </div>
                <div>
                    <p><strong>Who: </strong>Adults</p>
                </div>
                <div>
                    <p>This one has a broader audience but also narrows its target audience with a specific “What”.</p>
                </div>
                <div>
                    <p>I hope this helps you with whatever projects you’re currently embarking on. Please don’t think of the 5 W’s as “set in stone”, they’re just an aid to help you ensure that your content has direction and a strong purpose/call to action attached to it.</p>
                </div>
                <hr />
                <div>
                    <p>Don’t live and die by the 5 W’s, but if you’re ever unsure about whether or not you’re being clear or covering everything you intended to, lean on the 5 W’s as an aid.</p>
                </div>

                
                
             </div>

            </div>
          </div>
        </div>
        <div>
            <Footer />
        </div>
        </>
    );
}

export default BlogSix;