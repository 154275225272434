import React from 'react';
import "../Contact/Contact.scss";
import { Link } from "react-router-dom";
import * as emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
// import Recaptcha from "react-recaptcha";
import { useForm } from "react-hook-form";

init("user_XU6qitEJVQPQitgAQfINJ");

const Contact = () => {

const { register, handleSubmit, reset, errors } = useForm();

const onSubmit = (data, e, event) => {
  console.log(data);
  e.preventDefault();
  emailjs.sendForm('service_0zfwi3c', 'template_6s7rmrv', e.target, 'user_XU6qitEJVQPQitgAQfINJ')
  .then((result) => {
      console.log(result.text);
      document.getElementById("successMessage").innerHTML = "Thank you for your interest in BDL, we will be in touch soon!";
      reset();
  }, (error) => {
      console.log(error.text);
      document.getElementById("errorMessage").innerHTML = "Sorry something went wrong, please check for mistakes and try again";
  });
}

    return (
      <div className={"contact horizontal-inner-in"}>
        <div className={"contact-section-head"}>
          <h3>Contact</h3>
        </div>
        <div className={"contact-inner"}>
        <div className={"form-cont"}>

        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
           <input type="hidden" name="contact_number" />
           <div className={"form-input-cont"}>
            <label>Name *</label>
            <input type="text" name="user_name" ref={register({ required: true })}/>
            <div className={"input-error-message"}>
              { errors.user_name && <p>This is required</p> }
            </div>
           </div>
           <div className={"form-input-cont"}>
            <label>Email *</label>
            <input type="email" name="user_email" ref={register({ required: true })}/>
            <div className={"input-error-message"}>
              { errors.user_email && <p>This is required</p> }
            </div>
           </div>
           <div className={"form-input-cont"}>
            <label>Phone *</label>
            <input type="phone" name="user_phone" ref={register({ required: true })} />
            <div className={"input-error-message"}>
              { errors.user_phone && <p>This is required</p> }
            </div>
           </div>
           <div className={"form-input-cont"}>
            <label>Tell us how we can help you?</label>
            <textarea name="message" ref={register()} />
           </div>
           <input type="submit" value="Send" />
           
           <div className={"form-message-cont"}>
              <span id={"successMessage"}></span>
              <span id={"errorMessage"}></span>
           </div>

        </form>
        </div>
        <div className={"bdl-info-cont"}>
          <div>
          <img src={"../../../../images/bdl-logos/BDL-stacked-square-white.png"} alt={"bdl-logo-contact"}></img>
          </div>
          <div className={"copyright"}>
            <span>© Bespoke Design Labs 2021<br></br>All Rights Reserved</span>
          </div>
          <div className={"tnc-cont"}>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </div>
      </div>
    );
  }

export default Contact;