import React from "react";
import "../ContentPage/ContentPage.scss";
import "../ServicesPage/ServicesPage.scss";
import Burger from "../Burger";
import Footer from "../Footer";
import ContactForm from "../ContactForm";
import { Link } from "react-router-dom";


class ServicesPage extends React.Component {
    render() {
     return (
        <div className={"services-page"}>
        <div>
        <Burger />
       </div>
       <div className={"inner-serv"}>
         <div className={"serv-page-logo-cont"}>
           <Link to={"/"}>
            <img src={"../../../images/bdl-logos/Bespoke-Logo-Long-Signature-White.png"} alt={"bdl-logo"} />
           </Link>
         </div>
         <div className={"serv-page-title-cont"}>
          <h1>Services</h1>
         </div>
       </div>
       <div className="serv-page-cont">
         <div className="serv-page-inner">
         <div className="serv-page-top">
            <div className="serv-page-top-inner serv-page-top-inner-left">
                <h3>What we offer</h3>
                <p>As a boutique agency, BDL works closely with you to discover appropriate digital solutions to take your company to the next level. Whether you are in a saturated market and need to differentiate from the competition, or are entering an emerging market and need to understand how to confidently progress towards your goals, we provide recommendations and services tailored to fit your needs.</p>
            </div>
            <div className="serv-page-top-inner serv-page-top-inner-right">
              <h3>Recent Work</h3>
              <div className="serv-page-top-inner-right-inner">
                <div className="serv-page-top-inner-col">
                  <div className="who-serve-cont">
                    <h4>NFT Community</h4>
                  </div>
                  <div className="who-serve-cont">
                    
                    <h4>Web3</h4>
                  </div>
                  <div className="who-serve-cont">
                      
                      <h4>E-Commerce</h4>
                  </div>
                  <div className="who-serve-cont">
                      
                      <h4>Non-Profits</h4>
                  </div>
                  <div className="who-serve-cont">
                      
                      <h4>Manufacturing</h4>
                  </div>
                  <div className="who-serve-cont">
                      
                      <h4>FinTech</h4>
                  </div>
                </div>
                <div className="serv-page-top-inner-col">
                    <div className="who-serve-cont">
                      
                      <h4>Decentralized Finance</h4>
                    </div>
                    <div className="who-serve-cont">
                      
                      <h4>DAOs</h4>
                    </div>
                    <div className="who-serve-cont">
                      
                      <h4>Affiliate Marketing</h4>
                    </div>
                  <div className="who-serve-cont">
                      
                      <h4>Women's Apparel</h4>
                  </div>
                  <div className="who-serve-cont">
            
                      <h4>Events/Entertainment</h4>
                  </div>
                  <div className="who-serve-cont">
                      
                      <h4>Fitness Tech</h4>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="serv-page-block-cont">
          <div className="serv-block">
            <div className="serv-block-top">
              <h3>Blockchain Development</h3>
            </div>
            <div className="serv-block-body">
              <p>Solidity <span>/</span> Smart Contracts <span>/</span> Staking Pools <span>/</span> NFT Platforms <span>/</span> NFT Games <span>/</span> Web3.js <span>/</span> RUST</p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

          <div className="serv-block" style={{backgroundColor: '#e9e9e9'}}>
            <div className="serv-block-top">
              <h3>Blockchain Services</h3>
            </div>
            <div className="serv-block-body">
              <p>Community Management <span>/</span> Marketing <span>/</span> Whitepapers <span>/</span> Consulting <span>/</span> Exchange Listings</p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

          <div className="serv-block" style={{backgroundColor: '#e0e0e0'}}>
            <div className="serv-block-top">
              <h3>Mobile App Development</h3>
            </div>
            <div className="serv-block-body">
              <p>Custom iOS & Android App Dev <span>/</span> QA & Testing <span>/</span> Rapid Prototyping <span>/</span> Agile Project Management <span>/</span> React Native</p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

          <div className="serv-block" style={{backgroundColor: '#afafaf'}}>
            <div className="serv-block-top">
              <h3>DevOps / Infrastructure</h3>
            </div>
            <div className="serv-block-body">
              <p>Agile Project Management <span>/</span> Continuous Integration <span>/</span> Consulting <span>/</span> Deployment Optimizations <span>/</span> Monitoring</p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

          <div className="serv-block" style={{backgroundColor: '#9b9b9b'}}>
            <div className="serv-block-top">
              <h3>Web App Development</h3>
            </div>
            <div className="serv-block-body">
              <p>ReactJS <span>/</span> NodeJS <span>/</span> AngularJS <span>/</span> Responsive UI | UX <span>/</span> Consulting <span>/</span> Isomorphic Web Applications <span>/</span> Performance Tuning </p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

          <div className="serv-block">
            <div className="serv-block-top">
              <h3>Product Design</h3>
            </div>
            <div className="serv-block-body">
              <p>Identity & Branding <span>/</span> Prototyping <span>/</span> High-fidelity Mockups <span>/</span> UI | UX <span>/</span> User and Brand Stories</p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

          <div className="serv-block" style={{backgroundColor: '#e9e9e9'}}>
            <div className="serv-block-top">
              <h3>Digital Marketing</h3>
            </div>
            <div className="serv-block-body">
              <p>Media Buying <span>/</span> Social Media Marketing <span>/</span> Affiliate Marketing <span>/</span> Email Campaigns <span>/</span> Content Marketing <span>/</span> SEO Strategy </p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

          <div className="serv-block">
            <div className="serv-block-top">
              <h3>Product Strategy</h3>
            </div>
            <div className="serv-block-body">
              <p>Market Research & Analysis <span>/</span> CRM Integration <span>/</span> Product Architecture <span>/</span> Consulting <span>/</span> Go to Market Strategies </p>
            </div>
            <div className="serv-block-cs">
              {/* <Link>View Case Study &gt;&gt;</Link> */}
            </div>
          </div>

        </div>

        <div className="serv-page-cs">
          <ContactForm />
        </div>

         </div>
        </div>
       <div>
        <Footer />
       </div>
      </div>
     );
  }
}

export default ServicesPage;